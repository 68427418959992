import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './style/css/index.css';

import reportWebVitals from './config/reportWebVitals';
import Footer from "./component/layout/Footer";
import Navbar from "./component/layout/Navbar";
import WelcomePage from "./component/layout/WelcomePage";
import WelcomeCciaaPage from "./component/layout/WelcomeCciaaPage";

import Completed from "./component/layout/Completed";
import Error from "./component/layout/Error";
import Help from "./component/layout/Help";

import CreaEtichetta from "./component/service/CreaEtichetta";
import ModificaEtichetta from "./component/service/ModificaEtichetta";
import MostraEtichetta from "./component/service/MostraEtichetta";
import AnteprimaEtichetta from "./component/service/AnteprimaEtichetta";

import DashboardEtichette from "./component/service/DashboardEtichette";

import AlertMessage from "./component/layout/AlertMessage";


import ChangeLang from "./component/layout/ChangeLang";
import {properties} from "./config/properties";


const root = ReactDOM.createRoot(document.getElementById('root'));

var url = new URL( window.location.href);
console.log(url)
var lang = url.searchParams.get("lang")!=null ? url.searchParams.get("lang") : "it";
//let currc = url.searchParams.get("cciaa")!=null ? url.searchParams.get("cciaa") : "";
let authToken = url.searchParams.get("authToken")!=null ? url.searchParams.get("authToken") : "";
if(authToken!= ""){
    let currc = url.pathname.includes("/cciaa/")? url.pathname.split("/cciaa/")[1].split("/")[0]: "bz";
    var token = makeHttpCall("/std/service/getToken?authToken="+authToken+"&cciaa="+currc)
    url.searchParams.delete("authToken");
    window.location.href = url.toString();


}

var cciaas = makeHttpCall("/std/service/getCcias")
var user = makeHttpCall("/std/service/getUser")
console.log(user, cciaas)



root.render(
    <React.StrictMode>
                {
                    url.pathname=="/frntWeb/visualizza/etichetta" ?
                        <>
                                <ChangeLang conf={{lang}}/>
                                <BrowserRouter>
                                <Routes>
                                    <Route path={"/frntWeb/visualizza/etichetta"  }   element={<MostraEtichetta conf={{lang}} hideElementGlobal={(element, show) => hideElementGlobal(element,show)}/>} />
                                </Routes>
                                </BrowserRouter>
                        </>
                    :
                    user.resultStatus != "true" &&  user.resultStatus != true ?
                        <>
                            <Navbar conf={{lang}} user={user.resultStatus == "true" || user.resultStatus == true ? user.resultData.email: ""}/>
                            <BrowserRouter>
                                <Routes>
                                    <Route  path={"/frntWeb"  } index element={<WelcomePage camere={{cciaas}}  conf={{lang}} hideElementGlobal={(element, show) => hideElementGlobal(element,show)}/>} />
                                    <Route path={"/frntWeb/help"  }  element={<Help conf={{lang}}/>} />
                                    {
                                        cciaas.map(item => (
                                            !item.attivo ?
                                            <>
                                                <Route
                                                    path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/creazione/etichetta"}
                                                    element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                           hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                <Route
                                                    path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/anteprima/etichetta"}
                                                    element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                           hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/modifica/etichetta"}
                                                       element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                              hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/dashboard"}
                                                       element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                              hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>

                                                <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() }
                                                       element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                              hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                            </>
                                                :
                                                <>
                                                <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() }
                                                       element={<WelcomeCciaaPage camera={item } user={user.resultStatus == "true" || user.resultStatus == true ? user: {}} conf={{lang}} hideElementGlobal={(element, show) => hideElementGlobal(element,show)}/>}/>

                                                </>
                                        ))
                                    }

                                    <Route path={"*"  }  element={<Error conf={{lang}}/>} />

                                </Routes>
                            </BrowserRouter>
                            <Footer/>
                        </>
                    :
                    user.resultStatus == "true" || user.resultStatus == true &&
                            <>
                                <Navbar conf={{lang}} user={user.resultStatus == "true" || user.resultStatus == true ? user.resultData.email: ""}/>
                                <BrowserRouter>
                                    <Routes>
                                        <Route  path={"/frntWeb"  } index element={<WelcomePage camere={{cciaas}}  conf={{lang}} hideElementGlobal={(element, show) => hideElementGlobal(element,show)}/>} />
                                        <Route path={"/frntWeb/help"  }  element={<Help conf={{lang}}/>} />

                                        {
                                            cciaas.map(item => (
                                                item.attivo ?
                                                    <>
                                                        <Route
                                                            path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/creazione/etichetta"}
                                                            element={<CreaEtichetta conf={{lang}} user={{user}}
                                                                                    hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route
                                                            path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/anteprima/etichetta"}
                                                            element={<AnteprimaEtichetta conf={{lang}}
                                                                                         hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/modifica/etichetta"}
                                                               element={<ModificaEtichetta conf={{lang}} user={{user}}
                                                                                           hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/dashboard"}
                                                               element={<DashboardEtichette conf={{lang}} cciaa={{item}} user={{user}}
                                                                                            hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() }
                                                               element={<WelcomeCciaaPage camera={item }  conf={{lang}} hideElementGlobal={(element, show) => hideElementGlobal(element,show)}/>}/>

                                                    </>
                                                    :
                                                    <>
                                                        <Route
                                                            path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/creazione/etichetta"}
                                                            element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                                   hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route
                                                            path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/anteprima/etichetta"}
                                                            element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                                   hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/modifica/etichetta"}
                                                               element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                                      hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() + "/dashboard"}
                                                               element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                                      hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>

                                                        <Route path={"/frntWeb/cciaa/" + item.sigla.toLowerCase() }
                                                               element={<AlertMessage conf={{lang}} cciaa={{item}}
                                                                                      hideElementGlobal={(element, show) => hideElementGlobal(element, show)}/>}/>
                                                    </>
                                            ))


                                        }
                                        <Route path={"*"  }  element={<Error conf={{lang}}/>} />
                                    </Routes>
                            </BrowserRouter>
                            <Footer/>
                        </>
                }
    </React.StrictMode>
)

function makeHttpCall(url){
    let response = {};
    const request = new XMLHttpRequest();
    request.open('GET',properties.fullBaseUrl+properties.baseAppUrl+url, false);  // `false` makes the request synchronous
    request.send(null);
    response = request.response
    try{
        response= JSON.parse(response)


    }catch{
        response= {}

    }
    return response


}

function hideElementGlobal(id)
{

    let element = document.getElementById(id);
    if(element==null){}
    else {
        if (element.style.display !== 'none') {
            element.style.display = 'none';
        } else {
            element.style.display = 'block';
        }
    }
};
//show/hide element by id
function hideElementGlobal2(elements)
{

    for(let i = 0; i<elements.show.length;i++){
        let element = document.getElementById(elements.show[i]);

        if(element==null){}
        else {
            let elementLabel = document.getElementById(elements.show[i]+"Label");

            if (element.style.display !== 'none') {
                element.style.display = 'none';
                elementLabel.style.fontWeight="normal";

            } else {
                element.style.display = 'block';
                elementLabel.style.fontWeight="bold";
            }
        }


    }

    for(let p = 0; p< elements.hide.length;p++){
        let element = document.getElementById(elements.hide[p]);

        if(element==null){}
        else {

            element.style.display = 'none';

            let elementLabel = document.getElementById(elements.hide[p]+"Label");
            elementLabel.style.fontWeight="normal";

        }


    }

};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
