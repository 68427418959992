import React, {useState} from 'react';
import '../../style/css/App.css';

import {
    CardImg,
    Collapse,
    Icon,
    LinkList, LinkListItem,
    MegamenuItem,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler, NavItem,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem


} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import {properties} from "../../config/properties";

const NavbarIC = () => {


    const [openNav, setOpenNav] = useState(false);
    const toggle = () => {
        setOpenNav(!openNav);
    };


    const languages = [
        { code: 'it', image: '/it-flag.png' },
        { code: 'de', image: '/de-flag.png' },
        { code: 'en', image: '/en-flag.png' }
    ];
    const [dropdownOpen, setDropdownOpen] = useState(false);

    var url = new URL( window.location.href);
    let lang2 = url.searchParams.get("lang")!=null ? url.searchParams.get("lang") : "it";
    const [selectedLanguage, setSelectedLanguage] = useState(languages[languages.findIndex(lang => lang.code === lang2)]);



    const toggle2 = () => setDropdownOpen(prevState => !prevState);


    let langSel = lang2
    let langUrl = langSel == 'it' ? "/" : "/?lang=" + langSel;


    const changeLang = (newLang) => {
        url.searchParams.set('lang', newLang)
        window.location.href = url.href;
    };
    return (
        <Navbar expand='lg' className='has-megamenu' style={{backgroundColor:"white", padding:"0px"}} >

            <Nav className={"ml-auto"} id={"changeLangView"} >

                <NavItem style={{ margin:"10px" }}>
                    <Dropdown  isOpen={dropdownOpen} toggle={toggle2}>
                        <DropdownToggle className={"navSelLang"} style={{backgroundColor:"lightgrey"}} >
                                <img style={{ borderRadius: "50%"}} src={properties.baseAppUrl + properties.baseImages + selectedLanguage.image} height={'12'} width={'12'} />
                        </DropdownToggle>
                        <DropdownMenu>
                            {languages.map((lang, index) => (
                                <a onClick={ ()=> changeLang(lang.code) } style={{ color: "white" }}>
                                <DropdownItem key={index} onClick={() => setSelectedLanguage(lang)}>

                                        <img style={{ borderRadius: "50%"}} src={properties.baseAppUrl + properties.baseImages + lang.image} height={'25'} width={'25'} />

                                </DropdownItem>
                                </a>
                            ))}
                        </DropdownMenu>
                    </Dropdown>

                </NavItem>




            </Nav>
        </Navbar>
    );
};
function getUserJS(){
    let response = {};
    const request = new XMLHttpRequest();
    request.open('GET', properties.baseAppUrl + properties.loggedUserUrl, false);  // `false` makes the request synchronous
    request.send(null);
    response = request.response

    try{
        response= JSON.parse(response)

    }catch{
        response= {userId : "Non autenticato"}

    }

    return response


}
export default NavbarIC;