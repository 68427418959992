//import React  , {useState} from 'react';
import React, {Component}  from 'react';
import '../../style/css/App.css';
import {properties} from "../../config/properties";



import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';


export default class EtichettaDashboard extends Component {
    constructor(props) {
        super(props);


    };


    render() {

        let langSel = this.props.conf.lang
        let cciaa = this.props.cciaa
        let langUrl = langSel == 'it' ? "/cciaa/"+cciaa.sigla.toLowerCase()+"/creazione/etichetta" : "/cciaa/"+cciaa.sigla.toLowerCase()+"/creazione/etichetta?lang=" + langSel;
        var langUrl2 = langSel == 'it' ? "/cciaa/"+cciaa.sigla.toLowerCase()+"/modifica/etichetta?id=" : "/cciaa/"+cciaa.sigla.toLowerCase()+"/modifica/etichetta?lang=" + langSel + "&id=";
        let langUrl3 = langSel == 'it' ? "/cciaa/"+cciaa.sigla.toLowerCase()+"/anteprima/etichetta?id=" : "/cciaa/"+cciaa.sigla.toLowerCase()+"/anteprima/etichetta?lang=" + langSel + "&id=";
        let langUrl4 = langSel == 'it' ? "/visualizza/etichetta?id=" : "/visualizza/etichetta?lang=" + langSel + "&id=";
        let langUrl5 = langSel == 'it' ? "/std/service/getZipFiles?id=" : "/std/service/getZipFiles?lang=" + langSel + "&id=";

        let langUrl6 =  "/std/service/depublishEtichetta?langSel="+ langSel + "&id=" ;
        let langUrl7 =  "/std/service/removeEtichetta?langSel="+ langSel + "&id=" ;


        return (
            <div

                className={"etDashList"}
                title={this.props.type == 'online' ? "Pubblicato il " +  new Date(this.props.item.dataUltimaModifica).toLocaleString() : "Creato il " + new Date(this.props.item.dataCreazione).toLocaleString()}
                onClick={  this.props.type == 'online' ||  this.props.type == 'bozza' ?  ()=>window.location.href=properties.baseAppUrl + langUrl2 +   this.props.item.codiceEtichetta : console.log("")}
                style={{cursor:  (this.props.type == 'online' ||  this.props.type == 'bozza') ? 'pointer' : '' }}
            >

                <div style={{
                    backgroundColor:  this.props.type == 'online' ? '#008758' : this.props.type == 'bozza'? 'lightgrey':'darkred',

                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'right',
                    flex: '0.5',
                    width:"100%"
                }}>

                    <div style={{width:"83%", paddingLeft:"3.14%", color: this.props.type != 'bozza'  ? 'white' :  'black' }}>
                        <b>{this.props.item.productYear}</b>

                    </div>

                    <div style={{width:"17%", paddingLeft:"3.14%"}}
                         onMouseEnter={() => this.props.hideElementGlobal(this.props.type +"_remove_"+this.props.index, false)  }
                         onMouseLeave={() => this.props.hideElementGlobal(this.props.type +"_remove_"+this.props.index, false)  }
                         title={this.props.type == 'bozza' && properties.labels.dashboard.titleRimuovi} >
                        {this.props.type == 'bozza' &&
                            <a href={properties.fullBaseUrl + properties.baseAppUrl + langUrl7 + this.props.item.codiceEtichetta}
                               style={{
                                   color: this.props.type == 'online' ? 'darkorange' : 'red',
                                   textDecoration: 'none',
                                   cursor: 'pointer'
                               }}>
                                <b> &#10539;</b>
                                    <span id={this.props.type + "_remove_" + this.props.index}
                                          className={"removeSpan"}
                                          style={{display: 'none', fontSize: "13px", textAlign: "left"}}>
                                                       <b>{properties.labels.dashboard.titleRimuovi}</b>
                                                   </span>
                            </a>

                        }

                    </div>

                </div>

                <div style={{
                    backgroundColor: this.props.type == 'online' ? '#008758' : this.props.type == 'bozza' ? 'lightgrey' : 'darkred',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: '3',
                    padding:'3.14%'

                }}

                >
                    <div style={{textDecoration: 'none', cursor: 'pointer', maxHeight: '60px', overflow: 'hidden'}}>


                                        <span style={{textDecoration: 'none', color: this.props.type != 'bozza'  ? 'white' : 'black' }}>
                                            <b>{this.props.item.productName}</b>
                                        </span>

                    </div>
                </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        flex: '2'
                    }}>

                        {this.props.type == 'online' ?
                        <div
                            title={properties.labels.dashboard.titleVisualizza}
                            style={{cursor: 'pointer'}}
                        >

                            <a   href={properties.baseAppUrl + langUrl4 +   this.props.item.codiceEtichetta}
                                 style={{textDecoration: 'none' }}>
                                &#128065;
                            </a>

                        </div>
                            :
                            this.props.type == 'bozza' &&
                            <div title={properties.labels.dashboard.titleAnteprima} style={{cursor: 'pointer'}} >
                                <a href={properties.baseAppUrl + langUrl3 + this.props.item.codiceEtichetta}
                                   style={{textDecoration: 'none'}}>
                                    &#128065;
                                </a>


                            </div>
                        }

                        {this.props.type != 'cestino' ?
                            <div title={properties.labels.dashboard.titleAnteprima} style={{cursor: 'pointer'}} >
                                <a title={properties.labels.dashboard.titleModifica}
                                   href={properties.baseAppUrl + langUrl2 + this.props.item.codiceEtichetta}
                                   style={{textDecoration: 'none', cursor: 'pointer'}}>
                                    &#9998;
                                </a>
                            </div>
                            :
                            <div title={properties.labels.dashboard.titleRipristina} style={{cursor: 'pointer'}} >
                                <a title={properties.labels.dashboard.titleRipristina}
                                   href={properties.fullBaseUrl + properties.baseAppUrl + langUrl6 + this.props.item.codiceEtichetta}
                                   style={{textDecoration: 'none', cursor: 'pointer'}}>
                                    &#8634;
                                </a>
                            </div>


                        }
                        {this.props.type != 'cestino' &&
                            <div title={properties.labels.dashboard.titleDownload} style={{cursor: 'pointer'}} >
                                <a title={properties.labels.dashboard.titleDownload}
                                   href={properties.fullBaseUrl + properties.baseAppUrl + langUrl5 + this.props.item.codiceEtichetta}
                                   style={{textDecoration: 'none', cursor: 'pointer'}}>
                                    &#10515;
                                </a>
                            </div>

                        }

                    </div>
            </div>
        );
    };

}
