//import React  , {useState} from 'react';
import React, {Component}  from 'react';
import '../../style/css/App.css';
import {properties} from "../../config/properties";
import styles from '../../style/css/styles.json'



import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';


export default class CreaEtichetta extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)

        let url = new URL( window.location.href);
        let idEt = url.searchParams.get("id")!=null ? url.searchParams.get("id") : "";

        //scarico etichetta da id
        let getEtichetta = {"gabbiettaCode":null,"capsulaCode":null,"productYear":null,"tappoCode":null,"bottigliaCode":null,   "bottleSize":null,"ingredienti":{"list":[]},"glicerinaPres":null,"dataCreazione":null,"zuccRes":null,"alcPres":null,"description":null,"lotNumber":null,"tabellaValNutrizionali":{"zuccheri":"0 g","energia":"0 kcal / 0 kJ","carboidrati":"0 g"},"acPres":null,"productionPlace":null,"productName":null,"bottledBy":null,"alcoholPercentage":null,"contenuti":{"hideErr":true,"showMenu":true,"text":"","list":[]},"codiceEtichetta":null,"conservanti":{"hideErr":true,"showMenu":true,"text":"","list":[]},"id":null,"lang":null, "online":null}

        //scarico liste ingredienti, contenuti e traduzioni
        let getListaIngredienti = makeHttpCall(properties.getIngredienti)
        let getListaContenuti = makeHttpCall(properties.getContenuti)
        let getListaTraduzioni = makeHttpCall(properties.getTraduzioni)

        //inizializzo lo state
        this.state = {
            service:{
                showQr:false,
                showTable:false,
                prodotti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                luogo:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                descrizione:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                ingredienti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                conservanti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                contenuti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                bottigliaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                tappoCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                gabbiettaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                capsulaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                }



            },

            data:properties.data,
            converter:properties.converter,

        };

        //valorizzo etichetta
        this.state.etichetta = getEtichetta
        this.state.etichetta.productYear = new Date().getFullYear()
        this.state.etichetta.description  =6
        this.state.etichetta.selLang = this.props.conf.lang

        this.state.riciclo= {
            "bottigliaCode": getEtichetta.bottigliaCode ,
            "tappoCode": getEtichetta.tappoCode ,
            "gabbiettaCode": getEtichetta.gabbiettaCode ,
            "capsulaCode": getEtichetta.capsulaCode

        }



        //valorizzo liste da tabelle
        this.state.data.ingredienti = getListaIngredienti.map(item2 => ({"el": item2['nome' + this.props.conf.lang.charAt(0).toUpperCase() + this.props.conf.lang.slice(1)],
            "code":item2.codiceIngrediente  }  ))
        this.state.data.contenuti = getListaContenuti.map(item2 => ({"el": item2['nome' + this.props.conf.lang.charAt(0).toUpperCase() + this.props.conf.lang.slice(1)],
            "code":item2.codiceIngrediente  }  ))
        this.state.data.traduzioni = getListaTraduzioni

        //creo una lista contenente tutti gli ingredienti/contenuti allergeni, con codice elemento e label allergene
        this.state.data.allergeni =
            [ ...(getListaIngredienti
                .filter(item2 => item2.labelAllergene !== null)
                .map(item2 => ({"code":item2.codiceIngrediente, "labelAllergene": item2.labelAllergene}))),
            ...(getListaContenuti
                .filter(item2 => item2.labelAllergene !== null)
                .map(item2 => ({"code":item2.codiceIngrediente, "labelAllergene": item2.labelAllergene})))]

        console.log(this.state.data)
        //valorizzo variabili di servizio per confronto testo in input e liste elementi
        this.state.service.prodotti.text =  getEtichetta.productName ?  this.state.data.prodotti
            .filter(item2 => item2.code.toString() === getEtichetta.productName.toString())
            .map(item2 => item2.el)[0]:"";

        /*this.state.service.luogo.text =  getEtichetta.productionPlace != null ? this.state.data.luogo
                .filter(item2 => item2.code.toString() === getEtichetta.productionPlace.toString())
                .map(item2 => item2.el)[0] : "";*/
        this.state.service.luogo.text =  getEtichetta.productionPlace != null ? getEtichetta.productionPlace : "";


        this.state.service.descrizione.text = getEtichetta.description != null ?  this.state.data.descrizione
            .filter(item2 => item2.code.toString() === getEtichetta.description.toString())
            .map(item2 => item2.el)[0] : "6";

        this.state.service.bottigliaCode.text = this.state.riciclo.bottigliaCode != null ?  this.state.data.bottigliaCode
            .filter(item2 => item2.code.toString() === this.state.riciclo.bottigliaCode.toString())
            .map(item2 => item2.el)[0] : "";

        this.state.service.tappoCode.text = this.state.riciclo.tappoCode != null ?  this.state.data.tappoCode
            .filter(item2 => item2.code.toString() === this.state.riciclo.tappoCode.toString())
            .map(item2 => item2.el)[0] : "";



        this.state.service.gabbiettaCode.text = this.state.riciclo.gabbiettaCode != null ?  this.state.data.gabbiettaCode
            .filter(item2 => item2.code.toString() === this.state.riciclo.gabbiettaCode.toString())
            .map(item2 => item2.el)[0] : "";

        this.state.service.capsulaCode.text = this.state.riciclo.capsulaCode != null ?  this.state.data.capsulaCode
            .filter(item2 => item2.code.toString() === this.state.riciclo.capsulaCode.toString())
            .map(item2 => item2.el)[0] : "";




    }




    render() {

        //valorizzo liste in base a digitazione nelle input delle liste
        let filteredProductionPlaceData = this.state.data.luogo.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.luogo.text.toLowerCase())
        );

        let filteredDescriptionData = this.state.data.descrizione.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.descrizione.text.toLowerCase())
        );

        let bottigliaCodeData = this.state.data.bottigliaCode.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.bottigliaCode.text.toLowerCase())
        );
        let tappoCodeData = this.state.data.tappoCode.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.tappoCode.text.toLowerCase())
        );

        let gabbiettaCodeData = this.state.data.gabbiettaCode.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.gabbiettaCode.text.toLowerCase())
        );
        let capsulaCodeData = this.state.data.capsulaCode.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.capsulaCode.text.toLowerCase())
        );


        let filteredIngrData = this.state.data.ingredienti.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.ingredienti.text.toLowerCase())
        );

        let filteredConsData = this.state.data.conservanti.filter((item) =>

            item.el.toLowerCase().includes( this.state.service.conservanti.text.toLowerCase())
        );

        let filteredContData = this.state.data.contenuti.filter((item) =>

            item.el.toLowerCase().includes(this.state.service.contenuti.text.toLowerCase())
        );
        return (

            <Container style={{minHeight: '480px', width: '93%', padding: '3.14%', borderLeft: '1px solid lightgrey'}}
            >

                {
                    this.props.user.user.privacyAccettata == "true" && (this.props.user.user.resultStatus == "true" || this.props.user.user.resultStatus == true)
                        ?
                        /*
                        <div className="alert alert-info">
                            <strong>Complimenti</strong>
                            <br/>
                            Hai accettato la privacy.
                            <div id="myModal"></div>
                        </div>
                        */  <div id="myModal2"></div>

                        :
                        <div id="myModal2" className="modal">
                            <div className="modal-content">

                                <form method="POST" encType="multipart/form-data"
                                      action={properties.fullBaseUrl + properties.baseAppUrl + "/std/service/savePolicyPrivacy" + "?redirectUri="+ window.location.href}

                                >

                                    {
                                        //<strong> Accetta le condizioni per utilizzare il servizio
                                        //    (obbligatorio)</strong><br/>
                                    }
                                    <div style={{marginBottom: '30px'}}>
                                        <input style={{cursor: 'pointer'}}
                                               type="checkbox"
                                               required
                                        />
                                        &nbsp; {properties.labels.dashboard.accettazioneCondizioni} <br/>
                                        <a href={properties.baseAppUrl + "/static/files" + '/2024%2007%2012%20EVIN%20Condizioni%20generali%20d%27uso.pdf'} target={"_blank"}>Download</a>
                                    </div>
                                    <div style={{marginBottom: '30px'}}>
                                        <input style={{cursor: 'pointer'}}
                                               type="checkbox"
                                               required
                                        />
                                        &nbsp;  {properties.labels.dashboard.accettazioneClausole} <br/>
                                        <ul>
                                            <li>{properties.labels.dashboard.accettazioneClausole_1}</li>
                                            <li>{properties.labels.dashboard.accettazioneClausole_2}</li>
                                            <li>{properties.labels.dashboard.accettazioneClausole_3}</li>
                                            <li>{properties.labels.dashboard.accettazioneClausole_4}</li>
                                            <li>{properties.labels.dashboard.accettazioneClausole_5}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <input style={{cursor: 'pointer'}}
                                               type="checkbox"
                                               required
                                        />
                                        &nbsp; {properties.labels.dashboard.accettazionePrivacy}  <br/>
                                        <a href={properties.baseAppUrl + "/static/files" + '/2024%2007%2011%20EVIN%20Privacy.pdf'}
                                           target={"_blank"}>Download</a>
                                    </div>
                                    <hr/>
                                    <div style={{textAlign: "center", marginTop: '30px'}}>
                                        <h4>
                                            <input className="btn btn-success blue"
                                                   id={"submitButton"}
                                                   type="submit" name="submit"
                                                   value={properties.labels.dashboard.accettazioneConferma}
                                            />
                                        </h4>
                                    </div>

                                </form>
                            </div>
                        </div>

                }


                <div align={"right"}>
                    <span style={{cursor: "pointer", paddingRight: "11%"}}
                          onClick={() => window.history.back()}><b>❮ {properties.labels.error.indietro}</b></span>
                </div>
                <form method="POST" encType="multipart/form-data"
                      action={this.props.user.user.privacyAccettata== "true" && (this.props.user.user.resultStatus == true || this.props.user.user.resultStatus == "true")  ?  properties.fullBaseUrl + properties.baseAppUrl + "/std/service/creaEtichetta" : ""}
                      onSubmit={(e) => this.saveEt2Send(e, this.state.etichetta)}
                      onKeyDown={(e) => {
                          if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
                              if (e.target.id == 'ingrList') {
                                  this.handleKeyPress(e, 'ingredienti')
                              } else if (e.target.id == 'consList') {
                                  this.handleKeyPress(e, 'conservanti')
                              } else if (e.target.id == 'contList') {
                                  this.handleKeyPress(e, 'contenuti')
                              }
                              e.preventDefault()
                          }

                      }}>

                    <div>
                        <h3 style={{
                            fontFamily: 'Titillium Web',
                            sansSerif: true
                        }}>{properties.labels.creaEtichetta.titolo}</h3>
                        <br/>
                        <div className="alert alert-info">
                            <strong>{properties.labels.creaEtichetta.warnMessageBold}</strong>
                            <br/>
                            {properties.labels.creaEtichetta.warnMessageDesc}
                        </div>
                        <br/>
                        <div id={"mainCont"} className={'mainCont'}>
                            <div id="inputComponents">

                                <label style={{
                                    fontFamily: 'Titillium Web',
                                    sansSerif: true
                                }}><b>{properties.labels.creaEtichetta.intestazione}</b></label>

                                <div style={{display: 'flex'}}>
                                    <div style={{width: '100%'}}>
                                        <label
                                            hidden={this.state.etichetta.productName != null && this.state.etichetta.productName != "" ? false : true}>{properties.labels.creaEtichetta.productName}</label>

                                    </div>

                                </div>

                                <div>
                                    <input type="text"
                                           onFocus={() => this.closeAllList()}
                                           style={{width: '100%'}}
                                           maxLength={255}
                                           placeholder={properties.labels.creaEtichetta.productName}

                                           defaultValue={this.state.etichetta.productName} id="productName"
                                           value={this.state.etichetta.productName}

                                           onChange={(e) => this.updateLabel('productName', e.target.value)} required/>


                                </div>

                                <br/>
                                <label style={{
                                    fontFamily: 'Titillium Web',
                                    sansSerif: true
                                }}><b>{properties.labels.creaEtichetta.liste}</b></label>

                                <p>
                                    <label>   {properties.labels.creaEtichetta.etichetta.ingredienti}:&nbsp;</label>
                                    <span id="ingredientsList"
                                          hidden={this.state.etichetta.ingredienti.list != undefined && this.state.etichetta.ingredienti.list.length == 0 && true}
                                          style={styles.ingredientsList}>
                            {this.state.etichetta.ingredienti.list.map((item, index) => (
                                <span
                                    id={"ingredienti_" + item.uuid.toString() + "_" + index}
                                    style={{cursor: "pointer", fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'}}
                                    className={"itemEtList"}
                                    key={index}
                                    onDoubleClick={() => this.removeElement("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}
                                    //onClick={() => this.renderBold("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}

                                    title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                >

                                    {
                                        this.state.data.ingredienti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.ingredienti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                            ))

                            }
                                </span>
                                    <br/>
                                    <label> {properties.labels.creaEtichetta.etichetta.contenuti}:&nbsp;</label>
                                    <span id="contieneList"
                                          hidden={this.state.etichetta.contenuti.list.length == 0 && true}

                                          style={styles.contieneList}>

                                        {this.state.etichetta.contenuti.list.map((item, index) => (
                                            <span
                                                id={"contenuti_" + item.uuid.toString() + "_" + index}
                                                style={{
                                                    cursor: "pointer",
                                                    fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'
                                                }}
                                                className={"itemEtList"}
                                                key={index}
                                                onDoubleClick={() => this.removeElement("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}
                                                //onClick={() => this.renderBold("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}

                                                title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                            >
                                    {
                                        this.state.data.contenuti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.contenuti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                                        ))

                                        }
                        </span>

                                </p>

                                <div>
                                    <div
                                        align={"left"}>
                                        <input
                                            id={"ingrList"}
                                            type={'text'}
                                            name={'text'}
                                            title={properties.labels.creaEtichetta.ingredienti.title}
                                            onFocus={() => this.closeOtherList('ingredienti')}

                                            placeholder={properties.labels.creaEtichetta.ingredienti.placeholder}
                                            autoComplete={"OFF"}
                                            value={this.state.service.ingredienti.text}
                                            onChange={(e) => this.handleChangeText('ingredienti', e.target.value)}
                                            onClick={(e) => this.handleClickText('ingredienti', false)}

                                            onKeyPress={(e) => this.handleKeyPress(e, 'ingredienti')}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                fontSize: '16px',
                                                marginBottom: '10px'
                                            }}
                                            required= {this.state.etichetta.ingredienti.list.length>0  ? false:true}
                                        />
                                        <div hidden={this.state.service.ingredienti.hideIngrList}>

                                            <ul className="modern-list">
                                                {filteredIngrData.map((item, index) => (
                                                    <li key={index} className="list-item"
                                                        onClick={() => this.handleClick(item.el, item.code, 'ingredienti')}>
                                                        {this.renderIngrCont(item.el)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <span style={{color: "darkred"}}
                                              hidden={this.state.service.ingredienti.hideErr}><b>{properties.labels.creaEtichetta.ingredienti.error}</b></span>
                                    </div>


                                    <div

                                        align={"left"}>
                                        <input
                                            id={"contList"}
                                            type={'text'}
                                            name={'text'}
                                            title={properties.labels.creaEtichetta.contenuti.title}
                                            onFocus={() => this.closeOtherList('contenuti')}
                                            placeholder={properties.labels.creaEtichetta.contenuti.placeholder}
                                            autoComplete={"OFF"}
                                            value={this.state.service.contenuti.text}
                                            onChange={(e) => this.handleChangeText('contenuti', e.target.value)}
                                            onClick={(e) => this.handleClickText('contenuti', false)}
                                            onKeyPress={(e) => this.handleKeyPress(e, 'contenuti')}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                fontSize: '16px',
                                                marginBottom: '10px'
                                            }}

                                        />
                                        <div hidden={this.state.service.contenuti.hideIngrList}>

                                            <ul className="modern-list">
                                                {filteredContData.map((item, index) => (
                                                    <li key={index} className="list-item"
                                                        onClick={() => this.handleClick(item.el, item.code, 'contenuti')}>
                                                        {this.renderIngrCont(item.el)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <span style={{color: "darkred"}}
                                              hidden={this.state.service.contenuti.hideErr}><b>{properties.labels.creaEtichetta.contenuti.error}</b></span>
                                    </div>

                                </div>

                                <br/>
                                <label style={{
                                    fontFamily: 'Titillium Web',
                                    sansSerif: true
                                }}><b>{properties.labels.creaEtichetta.generaTabella}</b></label>

                                {
                                    // zuccheri
                                }
                                <div>
                                    <label
                                        style={{width: "42%"}}>{properties.labels.creaEtichetta.zuccRes}</label>
                                    <label
                                        style={{width: "8%", fontSize: "15px"}}>(g/l)</label>
                                    <input title={properties.labels.creaEtichetta.zuccRes + " - g/l"}
                                           onFocus={() => this.closeAllList()} type="number" step="0.01" min="0"
                                           style={{width: "20%"}} id="zuccRes"
                                           defaultValue={this.state.etichetta.zuccRes}
                                           name="zuccRes"
                                           onWheel={(e) => e.target.blur()}
                                           onInput={(e) => this.sommaPerValori('zuccRes', e.target.value)} required/>

                                </div>

                                {
                                    // alcool
                                }
                                <div>
                                    <label
                                        style={{width: "42%"}}>{properties.labels.creaEtichetta.alcPres}</label>

                                    <label
                                        style={{width: "8%", fontSize: "15px"}}>(%)</label>
                                    <input onFocus={() => this.closeAllList()} type="number"
                                           step="0.1" min="0.0"
                                           max="100"
                                           value={this.state.etichetta.alcoholPercentage}
                                           defaultValue={this.state.etichetta.alcoholPercentage}
                                           style={{width: "20%"}}
                                           title={"Alcool % = Alcool (g/l) / 7.894"}
                                           id="alcoholPercentage"
                                           onWheel={(e) => e.target.blur()}
                                           onChange={(e) => this.updateLabelAlcool('alcoholPercentage', 'alcPres', e.target.value)}
                                    />
                                    <label
                                        style={{width: "2%"}}></label>
                                    <label
                                        style={{width: "8%", fontSize: "15px"}}>(g/l)</label>
                                    <input title={properties.labels.creaEtichetta.alcPres + " g/l"}
                                           onFocus={() => this.closeAllList()} type="number" step="0.1" min="0.00"
                                           style={{width: "20%"}} id="alcPres"
                                           value={this.state.etichetta.alcPres}
                                           name="alcPres"
                                           onWheel={(e) => e.target.blur()}
                                           onChange={(e) => this.updateLabelAlcool('alcPres', 'alcoholPercentage', e.target.value)}
                                           required/>

                                </div>

                                {
                                    // glicerina
                                }
                                <div>
                                    <label
                                        style={{width: "42%"}}>{properties.labels.creaEtichetta.glicerinaPres}</label>

                                    <label
                                        style={{width: "8%", fontSize: "15px"}}>(g/l)</label>
                                    <input
                                        title={properties.labels.creaEtichetta.glicerinaPres + " - g/l = Alcool (%) * 0.7894"}
                                        onFocus={() => this.closeAllList()} type="number" step="0.01" min="0.00"
                                        style={{width: "20%"}} id="glicerinaPres"
                                        value={this.state.etichetta.glicerinaPres}
                                        name="glicerinaPres"
                                        onInput={(e) => this.sommaPerValori('glicerinaPres', e.target.value)}
                                        required
                                        disabled/>

                                </div>

                                {
                                    // acidita' residua
                                }
                                <div>
                                    <label
                                        style={{width: "42%"}}>{properties.labels.creaEtichetta.acPres}</label>

                                    <label
                                        style={{width: "8%", fontSize: "15px"}}>(g/l)</label>
                                    <input title={properties.labels.creaEtichetta.acPres + " - g/l"}
                                           onFocus={() => this.closeAllList()} type="number" step="0.01" min="0.00"
                                           style={{width: "20%"}} id="acPres" defaultValue={this.state.etichetta.acPres}
                                           name="acPres"
                                           onWheel={(e) => e.target.blur()}
                                           onInput={(e) => this.sommaPerValori('acPres', e.target.value)}/>
                                </div>

                                <br/>
                                <br/>
                                <label style={{
                                    fontFamily: 'Titillium Web',
                                    sansSerif: true
                                }}><b>{properties.labels.creaEtichetta.ricicloLabel}</b></label>

                                <div style={{zIndex: 100}}>
                                    <div
                                        style={{display: 'flex'}}
                                    >
                                        <div
                                            style={{flex: 1}}
                                            align={"left"}>
                                            <br/>
                                            <label
                                                hidden={(this.state.service.bottigliaCode.text != null && this.state.service.bottigliaCode.text != "") ? false : true}>{properties.labels.creaEtichetta.riciclo.bottigliaInput}</label>


                                        </div>

                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>
                                            <br/>
                                            <label
                                                hidden={(this.state.service.tappoCode.text != null && this.state.service.tappoCode.text != "") ? false : true}>{properties.labels.creaEtichetta.riciclo.tappoInput}</label>


                                        </div>


                                    </div>

                                    <div
                                        style={{display: 'flex'}}
                                    >
                                        <div
                                            style={{flex: 1}}
                                            align={"left"}>
                                            <input type="text"
                                                   placeholder={properties.labels.creaEtichetta.riciclo.bottigliaInput}

                                                   value={
                                                       this.state.service.bottigliaCode.text
                                                   }

                                                   id="bottigliaCode"
                                                   onChange={(e) => this.handleChangeText("bottigliaCode", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("bottigliaCode", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('bottigliaCode')}
                                                   onBlurCapture={(e) => this.checkField(e, 'bottigliaCode', 'bottigliaCode')}
                                                   required
                                            />
                                            <span id={"bottigliaCodeSpan"} style={{display: 'none'}}> &#8599;</span>

                                            <div hidden={this.state.service.bottigliaCode.hideIngrList}>

                                                <ul className="modern-list">
                                                    {bottigliaCodeData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleRecycleTextClick(item.el, item.code, 'bottigliaCode', "bottigliaCode")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>

                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>
                                            <input type="text"

                                                   value={
                                                       this.state.service.tappoCode.text
                                                   }
                                                   placeholder={properties.labels.creaEtichetta.riciclo.tappoInput}
                                                   id="tappoCode"
                                                   onChange={(e) => this.handleChangeText("tappoCode", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("tappoCode", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('tappoCode')}
                                                   onBlurCapture={(e) => this.checkField(e, 'tappoCode', 'tappoCode')}
                                                   required
                                            />
                                            <span id={"tappoCodeSpan"} style={{display: 'none'}}> &#8599;</span>

                                            <div hidden={this.state.service.tappoCode.hideIngrList}>

                                                <ul className="modern-list">
                                                    {tappoCodeData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleRecycleTextClick(item.el, item.code, 'tappoCode', "tappoCode")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>


                                    </div>
                                    <div
                                        style={{display: 'flex'}}
                                    >


                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>
                                            <br/>
                                            <label
                                                hidden={(this.state.service.gabbiettaCode.text != null && this.state.service.gabbiettaCode.text != "") ? false : true}>{properties.labels.creaEtichetta.riciclo.gabbiettaInput}</label>


                                        </div>

                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>
                                            <br/>
                                            <label
                                                hidden={(this.state.service.capsulaCode.text != null && this.state.service.capsulaCode.text != "") ? false : true}>{properties.labels.creaEtichetta.riciclo.capsulaInput}</label>


                                        </div>
                                    </div>

                                    <div
                                        style={{display: 'flex'}}
                                    >

                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>

                                            <input type="text"

                                                   value={
                                                       this.state.service.gabbiettaCode.text
                                                   }
                                                   placeholder={properties.labels.creaEtichetta.riciclo.gabbiettaInput}
                                                   id="gabbiettaCode"
                                                   onChange={(e) => this.handleChangeText("gabbiettaCode", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("gabbiettaCode", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('gabbiettaCode')}
                                                   onBlurCapture={(e) => this.checkField(e, 'gabbiettaCode', 'gabbiettaCode')}

                                            />
                                            <span id={"gabbiettaCodeSpan"} style={{display: 'none'}}> &#8599;</span>

                                            <div hidden={this.state.service.gabbiettaCode.hideIngrList}>

                                                <ul className="modern-list">
                                                    {gabbiettaCodeData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleRecycleTextClick(item.el, item.code, 'gabbiettaCode', "gabbiettaCode")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>

                                        <div
                                            style={{flex: 1}}

                                            align={"left"}>

                                            <input type="text"

                                                   value={
                                                       this.state.service.capsulaCode.text
                                                   }
                                                   placeholder={properties.labels.creaEtichetta.riciclo.capsulaInput}
                                                   id="capsulaCode"
                                                   onChange={(e) => this.handleChangeText("capsulaCode", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("capsulaCode", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('capsulaCode')}
                                                   onBlurCapture={(e) => this.checkField(e, 'capsulaCode', 'capsulaCode')}

                                            />
                                            <span id={"capsulaCodeSpan"} style={{display: 'none'}}> &#8599;</span>

                                            <div hidden={this.state.service.capsulaCode.hideIngrList}>

                                                <ul className="modern-list">
                                                    {capsulaCodeData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleRecycleTextClick(item.el, item.code, 'capsulaCode', "capsulaCode")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <label style={{fontFamily: 'Titillium Web', sansSerif: true, cursor: 'pointer'}}
                                       onClick={() => this.props.hideElementGlobal("extraInfo")}><b>Extra
                                    (Facoltativo) &nbsp; &#10097;</b></label>


                                <br/>
                                <div id={"extraInfo"} style={{display: 'none', zIndex: 100}}>

                                    <div>

                                        <div className="alert alert-info">

                                            <p>{properties.labels.creaEtichetta.warnMessageFac}</p>
                                        </div>

                                        <div>
                                            <label
                                                style={{width: '60%'}}
                                                hidden={(this.state.etichetta.productName != null && this.state.etichetta.productName != "" || this.state.etichetta.productYear != null && this.state.etichetta.productYear != "") ? false : true}>{properties.labels.creaEtichetta.productName}</label>

                                            <label
                                                style={{width: '40%'}}

                                                hidden={(this.state.etichetta.productYear != null && this.state.etichetta.productYear != "" || this.state.etichetta.productName != null && this.state.etichetta.productName != "") ? false : true}>{properties.labels.creaEtichetta.productYear}</label>

                                            <br/>
                                            <input type="text"
                                                   onFocus={() => this.closeAllList()}
                                                   style={{width: '60%'}}
                                                   maxLength={255}
                                                   defaultValue={this.state.etichetta.productName}
                                                   placeholder={properties.labels.creaEtichetta.productName}
                                                   id={"productName2"}
                                                   onChange={(e) => this.updateLabel('productName2', e.target.value)}
                                            />
                                            <input onFocus={() => this.closeAllList()} type="number" step="1" min="1900"
                                                   max="2050" defaultValue={this.state.etichetta.productYear}
                                                   style={{width: '40%'}} placeholder={"Anno"} id="productYear"
                                                   onInput={(e) => this.updateLabel('productYear', e.target.value)}
                                            />


                                        </div>
                                        <div
                                            align={"left"}>
                                            <br/>
                                            <label
                                                style={{width: '100%'}}
                                                hidden={(this.state.service.descrizione.text != null && this.state.service.descrizione.text != "") ? false : true}>{properties.labels.creaEtichetta.description}</label>
                                            <input type="text"

                                                   value={
                                                       this.state.service.descrizione.text
                                                   }
                                                   placeholder={properties.labels.creaEtichetta.description}
                                                   id="description"
                                                   onChange={(e) => this.handleChangeText("descrizione", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("descrizione", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('descrizione')}
                                                   onBlurCapture={(e) => this.checkField(e, 'descrizione', 'description')}

                                            />
                                            <span id={"descriptionSpan"} style={{display: 'none'}}> &#8599;</span>

                                            <div hidden={this.state.service.descrizione.hideIngrList}>

                                                <ul className="modern-list">
                                                    {filteredDescriptionData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleTextClick(item.el, item.code, 'descrizione', "description")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                        <div>
                                            <br/>
                                            <label
                                                style={{width: '60%'}}
                                                hidden={(this.state.etichetta.bottleSize != null && this.state.etichetta.bottleSize != "" || this.state.etichetta.lotNumber != null && this.state.etichetta.lotNumber != "") ? false : true}>{properties.labels.creaEtichetta.lotNumber}</label>
                                            <label
                                                style={{width: '40%'}}
                                                hidden={(this.state.etichetta.bottleSize != null && this.state.etichetta.bottleSize != "" || this.state.etichetta.lotNumber != null && this.state.etichetta.lotNumber != "") ? false : true}>{properties.labels.creaEtichetta.bottleSize}</label>

                                            <input type="text" maxLength={25} onFocus={() => this.closeAllList()}
                                                   placeholder={properties.labels.creaEtichetta.lotNumber}
                                                   style={{width: '60%'}}
                                                   defaultValue={this.state.etichetta.lotNumber} id="lotNumber"
                                                   onChange={(e) => this.updateLabel('lotNumber', e.target.value)}/>

                                            <input onFocus={() => this.closeAllList()} type="number" step="0.05"
                                                   min="0.1"
                                                   max="54" defaultValue={this.state.etichetta.bottleSize}
                                                   style={{width: '40%'}}
                                                   placeholder={properties.labels.creaEtichetta.bottleSize}
                                                   id="bottleSize"
                                                   onInput={(e) => this.updateLabel('bottleSize', e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <br/>
                                            <label
                                                style={{width: '60%'}}
                                                hidden={(this.state.etichetta.bottledBy != null && this.state.etichetta.bottledBy != "") ? false : true}>{properties.labels.creaEtichetta.bottledBy}</label>

                                            <input type="text" maxLength={100} onFocus={() => this.closeAllList()}
                                                   placeholder={properties.labels.creaEtichetta.bottledBy}
                                                   defaultValue={this.state.etichetta.bottledBy} id="bottledBy"
                                                   onChange={(e) => this.updateLabel('bottledBy', e.target.value)}/>
                                        </div>
                                        <div
                                            align={"left"}>

                                            <br/>
                                            <label
                                                style={{width: '60%'}}
                                                hidden={(this.state.service.luogo.text != null && this.state.service.luogo.text != "") ? false : true}>{properties.labels.creaEtichetta.productionPlace}</label>

                                            <input type="text"

                                                   value={
                                                       this.state.service.luogo.text
                                                   }
                                                   placeholder={properties.labels.creaEtichetta.productionPlace}
                                                   id="productionPlace"
                                                   onChange={(e) => this.handleChangeText("luogo", e.target.value)}
                                                   onClick={(e) => this.handleChangeText("luogo", e.target.value)}
                                                   autoComplete={"OFF"}
                                                   onFocus={() => this.closeOtherList('luogo')}
                                            />
                                            <span id={"productionPlaceSpan"} style={{display: 'none'}}> &#8599;</span>
                                            <div hidden={this.state.service.luogo.hideIngrList}>

                                                <ul className="modern-list">
                                                    {filteredProductionPlaceData.map((item, index) => (
                                                        <li key={index} className="list-item"
                                                            onClick={() => this.handleTextClick(item.el, item.el, 'luogo', "productionPlace")}>
                                                            {item.el}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>


                                        <br/>


                                    </div>
                                </div>


                            </div>
                            <div style={styles.divEditor} id="divEditor" onClick={(e) => this.closeAllList()}>

                                <div style={styles.divEtichetta} id="divEtichetta">

                                    { /*
                                    <div style={{textAlign: 'center'}}>

                                    <span
                                        hidden={this.state.etichetta.productName2 == true ? true : false}>{properties.labels.creaEtichetta.etichetta.productNameLabel}
                                        <label
                                            style={styles.itemEtList}
                                            className={"itemEtList"}
                                            onClick={() => this.renderBold("prodotti", "productNameLabel")}
                                            id="productNameLabel">{this.state.etichetta.productName}</label></span>

                                    </div>

                                    <div style={{textAlign: 'center'}}>



                                   <span>
                                       <label style={styles.itemEtList}
                                              className={"itemEtList"}
                                              onClick={() => this.renderBold("prodotti", "productYearLabel")}
                                              id="productYearLabel">{this.state.etichetta.productYear}</label>
                                   </span>


                                    </div>

                                    <div
                                        hidden={this.state.etichetta.bottleSize == "" || this.state.etichetta.bottleSize == null && true}>
                                        <span> <label
                                            id="bottleSizeLabel">{this.state.etichetta.bottleSize}</label> <span
                                            hidden={this.state.etichetta.bottleSize == "" || this.state.etichetta.bottleSize == null && true}>L - </span> <span> <label
                                            id="alcoholPercentageLabel">{(this.state.etichetta.alcoholPercentage != null && this.state.etichetta.alcoholPercentage != 'NaN') ? this.state.etichetta.alcoholPercentage : "0"}</label> <span
                                            hidden={this.state.etichetta.alcoholPercentage == "" || this.state.etichetta.alcoholPercentage == null && true}>%</span></span></span>
                                    </div>
                                    */ }
                                    {this.state.service.showQr ?
                                        <p>



                                            <span id="contieneList"
                                                  style={styles.contieneList}>{properties.labels.creaEtichetta.etichetta.contenuti}:&nbsp;

                                                {this.state.etichetta.contenuti.list.map((item, index) => (
                                                    <span
                                                        id={"contenuti_" + item.uuid.toString() + "_" + index}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'
                                                        }}
                                                        className={"itemEtList"}
                                                        key={index}
                                                        onDoubleClick={() => this.removeElement("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}
                                                        //onClick={() => this.renderBold("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}

                                                        title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                                    >
                                                        {
                                                            this.state.data.contenuti
                                                                .filter(item2 => item2.code.toString() === item.uuid.toString())
                                                                .map(item2 => item2.el)
                                                            + (index < this.state.etichetta.contenuti.list.length - 1 ? ", " : " ")
                                                        }
                                                    </span>
                                                ))

                                                }
                                            </span>


                                        </p>
                                        :
                                        <p>
                        <span id="ingredientsList"
                              hidden={this.state.etichetta.ingredienti.list.length == 0 && true}
                              style={styles.ingredientsList}>
                            <label>{properties.labels.creaEtichetta.etichetta.ingredienti}:&nbsp;</label>
                            {this.state.etichetta.ingredienti.list.map((item, index) => (
                                <span
                                    id={"ingredienti_" + item.uuid.toString() + "_" + index}
                                    style={{cursor: "pointer", fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'}}
                                    className={"itemEtList"}
                                    key={index}
                                    onDoubleClick={() => this.removeElement("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}
                                    //onClick={() => this.renderBold("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}

                                    title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                >

                                    {
                                        this.state.data.ingredienti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.ingredienti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                            ))

                            }
                                </span>
                                <br/>
                                            <span id="contieneList"
                                                  hidden={this.state.etichetta.contenuti.list.length == 0 && true}

                                                  style={styles.contieneList}>

                                                {this.state.etichetta.contenuti.list.map((item, index) => (
                                                    <span
                                                        id={"contenuti_" + item.uuid.toString() + "_" + index}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'
                                                        }}
                                                        className={"itemEtList"}
                                                        key={index}
                                                        onDoubleClick={() => this.removeElement("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}
                                                        //onClick={() => this.renderBold("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}

                                                        title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                                    >
                                    {
                                        this.state.data.contenuti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.contenuti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                                                ))

                                                }
                        </span>

                                        </p>


                                    }


                                    <div style={{fontSize: "0.5em"}}>&nbsp;</div>

                                    {this.state.service.showQr ?
                                        <div style={styles.qrPar} onClick={(e) => {
                                            this.showQrOrTable(!this.state.service.showQr)
                                        }}>
                                            <p>
                                                {properties.labels.creaEtichetta.etichetta.qrCode}

                                            </p>
                                            <div align={"right"}>

                                                <img style={{width: "30%", padding: "5px"}}
                                                     src={properties.fullBaseUrl + properties.baseAppUrl + "/std/service/getQRCode?id=" + this.state.etichetta.codiceEtichetta}/>

                                            </div>
                                        </div>
                                        :
                                        <table id="tblNutrition" style={styles.tblNutrition} onClick={(e) => {
                                            //   this.showQrOrTable(!this.state.service.showQr)
                                        }}>
                                            <tr>
                                                <th style={styles.th}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.intestazione1}</th>
                                                <th style={styles.th}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.intestazione2}</th>
                                            </tr>
                                            <tr>
                                                <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.energia}</td>
                                                <td style={styles.td}>
                                                    <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.energia.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.energia
                                                    }
                                                </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.carboidrati}</td>
                                                <td style={styles.td}>
                                                    <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.carboidrati.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.carboidrati
                                                    }
                                                </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.zuccheri}</td>
                                                <td style={styles.td}>
                                                    <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.zuccheri.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.zuccheri
                                                    }
                                                </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.bottom1}<br/>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.bottom2}
                                                </td>
                                                <td style={styles.td}></td>
                                            </tr>

                                        </table>
                                    }

                                    <div style={styles.labelSection} >
                                        <br/>
                                        <br/>
                                        {
                                            this.renderAllergeniMultilang(this.state.etichetta.ingredienti.list,
                                                this.state.etichetta.contenuti.list,
                                                this.state.data.allergeni,
                                                this.state.data.traduzioni,
                                                this.state.data.lingueCols)
                                        }
                                        <br/>
                                        <br/>
                                    </div>

                                    { /*
                                    <div id="labelSection" style={styles.labelSection}>
                                        <br/>
                                        <span
                                            hidden={this.state.etichetta.productionPlace == "" || this.state.etichetta.productionPlace == null && true}>{properties.labels.creaEtichetta.etichetta.productionPlaceLabel} &nbsp;

                                            <label id="productionPlaceLabel"
                                                   onClick={() => this.renderBold("luogo", "productionPlaceLabel")}
                                                   onDoubleClick={() => this.removeSingleElement("productionPlace", "luogo")}
                                                   style={styles.itemEtList}
                                                   className={"itemEtList"}

                                            >
                                        {
                                            this.state.etichetta.productionPlace
                                        }
                                    </label>

                                </span>


                                        <br/>
                                        <span
                                            hidden={this.state.etichetta.bottledBy == "" || this.state.etichetta.bottledBy == null && true}>{properties.labels.creaEtichetta.etichetta.bottledByLabel} &nbsp;
                                            <label style={styles.itemEtList}
                                                   className={"itemEtList"}
                                                   onClick={() => this.renderBold("prodotti", "bottledByLabel")}
                                                   id="bottledByLabel">{this.state.etichetta.bottledBy}
                                            </label>
                                        </span>
                                        <br/>
                                        <span
                                            hidden={this.state.etichetta.lotNumber == "" || this.state.etichetta.lotNumber == null && true}>{properties.labels.creaEtichetta.etichetta.lotNumberLabel} &nbsp;
                                            <label style={styles.itemEtList}
                                                   onClick={() => this.renderBold("prodotti", "lotNumberLabel")}
                                                   className={"itemEtList"}
                                                   id="lotNumberLabel">{this.state.etichetta.lotNumber}
                                            </label>
                                        </span>
                                        <br/>
                                        <span>
                                    <label id="descriptionLabel"
                                           onClick={() => this.renderBold("descrizione", "descriptionLabel")}
                                           onDoubleClick={() => this.removeSingleElement("description", "descrizione")}
                                           style={styles.itemEtList}
                                           className={"itemEtList"}

                                    >
                                            {this.state.etichetta.description != null && this.state.etichetta.description != 6 ?
                                                this.state.data.descrizione
                                                    .filter(item2 => item2.code.toString() === this.state.etichetta.description.toString())
                                                    .map(item2 => item2.el) : ""
                                            }
                                        </label>

                                    </span>


                                    </div>
                                    */ }

                                </div>
                                <br/>
                                <div id="bottledEditor" onClick={(e) => this.closeAllList()}>
                                    <br/>
                                    <br/>
                                    <img style={{width: "20%", padding: "5px"}}
                                         src={properties.baseAppUrl + properties.baseImages + '/anonymus_bottle.png'}/>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '75%',
                                        textAlign: 'center'
                                    }}>
                                        <table id="bottleTable" style={{
                                            borderCollapse: 'collapse',
                                            marginLeft: '20px auto',
                                            width: '100%'
                                        }}>
                                            <tr>
                                                <th style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    color: '#06c',
                                                    backgroundColor: '#C8F8FC'
                                                }}>{properties.labels.creaEtichetta.riciclo.bottigliaLabel}</th>
                                                <th style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    color: '#06c',
                                                    backgroundColor: '#C8F8FC'
                                                }}>{properties.labels.creaEtichetta.riciclo.tappoLabel}</th>
                                                {(this.state.riciclo.gabbiettaCode != null && this.state.riciclo.gabbiettaCode != "") &&
                                                    <th style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        color: '#06c',
                                                        backgroundColor: '#C8F8FC'
                                                    }}>{properties.labels.creaEtichetta.riciclo.gabbiettaLabel}</th>}
                                                {(this.state.riciclo.capsulaCode != null && this.state.riciclo.capsulaCode != "") &&
                                                    <th style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        color: '#06c',
                                                        backgroundColor: '#C8F8FC'
                                                    }}>{properties.labels.creaEtichetta.riciclo.capsulaLabel}</th>}

                                            </tr>

                                            <tr>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b> {this.state.riciclo.bottigliaCode != null ?
                                                        this.state.data.bottigliaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.bottigliaCode.toString())
                                                            .map(item2 => item2.codice) : ""
                                                    }</b>

                                                </td>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b>{this.state.riciclo.tappoCode != null ?
                                                        this.state.data.tappoCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.tappoCode.toString())
                                                            .map(item2 => item2.codice) : ""
                                                    }</b>
                                                </td>
                                                {(this.state.riciclo.gabbiettaCode != null && this.state.riciclo.gabbiettaCode != "") &&
                                                    <td style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        backgroundColor: 'lightgrey',
                                                        color: "#06c"
                                                    }}>
                                                        <b>  {this.state.riciclo.gabbiettaCode != null ?
                                                            this.state.data.gabbiettaCode
                                                                .filter(item2 => item2.code.toString() === this.state.riciclo.gabbiettaCode.toString())
                                                                .map(item2 => item2.codice) : ""
                                                        }</b>
                                                    </td>
                                                }

                                                {(this.state.riciclo.capsulaCode != null && this.state.riciclo.capsulaCode != "") &&
                                                    <td style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        backgroundColor: 'lightgrey',
                                                        color: "#06c"
                                                    }}>
                                                        <b> {this.state.riciclo.capsulaCode != null ?
                                                            this.state.data.capsulaCode
                                                                .filter(item2 => item2.code.toString() === this.state.riciclo.capsulaCode.toString())
                                                                .map(item2 => item2.codice) : ""
                                                        }</b>
                                                    </td>
                                                }


                                            </tr>
                                            <tr>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b> {this.state.riciclo.bottigliaCode != null ?
                                                        this.state.data.bottigliaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.bottigliaCode.toString())
                                                            .map(item2 => item2.el) : ""
                                                    }</b>
                                                </td>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b>  {this.state.riciclo.tappoCode != null ?
                                                        this.state.data.tappoCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.tappoCode.toString())
                                                            .map(item2 => item2.el) : ""
                                                    }
                                                        {this.state.riciclo.tappoEl}</b></td>

                                                {(this.state.riciclo.gabbiettaCode != null && this.state.riciclo.gabbiettaCode != "") &&
                                                    <td style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        backgroundColor: 'lightgrey',
                                                        color: "#06c"
                                                    }}>
                                                        <b>  {this.state.riciclo.gabbiettaCode != null ?
                                                            this.state.data.gabbiettaCode
                                                                .filter(item2 => item2.code.toString() === this.state.riciclo.gabbiettaCode.toString())
                                                                .map(item2 => item2.el) : ""
                                                        }
                                                            {this.state.riciclo.tappoEl}</b></td>
                                                }

                                                {(this.state.riciclo.capsulaCode != null && this.state.riciclo.capsulaCode != "") &&

                                                    <td style={{
                                                        border: '3px solid white',
                                                        padding: '8px',
                                                        backgroundColor: 'lightgrey',
                                                        color: "#06c"
                                                    }}>
                                                        <b>  {this.state.riciclo.capsulaCode != null ?
                                                            this.state.data.capsulaCode
                                                                .filter(item2 => item2.code.toString() === this.state.riciclo.capsulaCode.toString())
                                                                .map(item2 => item2.el) : ""
                                                        }
                                                            {this.state.riciclo.tappoEl}</b></td>
                                                }

                                            </tr>


                                        </table>
                                        <table id="tblBottle2" style={{
                                            borderCollapse: 'collapse',
                                            marginLeft: '20px auto',
                                            width: '100%',
                                            border: '1px solid #ddd'
                                        }}>
                                            <tr>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    color: '#06c',
                                                    backgroundColor: '#58D5DF'
                                                }}><b>{properties.labels.creaEtichetta.riciclo.descrizione2}</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    color: '#06c',
                                                    backgroundColor: '#C8F8FC'
                                                }}><b>{properties.labels.creaEtichetta.riciclo.descrizione3}</b></td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>
                                <br/>
                            </div>

                        </div>
                        <br/>
                        <div style={{display: 'flex'}}>


                        </div>
                        <div className="alert alert-warning"
                              id={"warningInfobox"}>
                            <span>
                               <input style={{cursor: 'pointer'}} onFocus={() => this.closeAllList()} type="checkbox"
                                      onClick={(e) => this.warningSuccess(e, true)}
                               />
                                &nbsp;    {properties.labels.creaEtichetta.warnMessageConf} &nbsp;
                                <a href={properties.baseAppUrl + "/static/files" + '/2024%2007%2012%20EVIN%20Condizioni%20generali%20d%27uso.pdf'}
                                   target={"_blank"}><b>Download</b></a>
                        </span>

                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1, textAlign: "right", padding: '1%'}} hidden={true}>
                                <h4>
                                    <button type="button"
                                            id="download"
                                            className="btn btn-info blue"
                                            onClick={(e) => this.saveEt2Send(this.state.etichetta)}>
                                        {properties.labels.creaEtichetta.previewBtn}
                                    </button>
                                </h4>

                            </div>

                            <div style={{flex: 1, textAlign: "center", padding: '1%'}}>


                                <h4>
                                    <input className="btn btn-success blue"
                                           id={"submitButton"}
                                           type="submit" name="submit" value={properties.labels.creaEtichetta.saveBtn}
                                           disabled/>
                                </h4>
                            </div>


                            <hr/>
                            <img id="outputImg"/>

                        </div>

                    </div>


                    <input type="text" name={"stateObj"} id={"stateObj"} hidden={true}/>
                    <input type="text" name={"etReq2s"} id={"etReq2s"} hidden={true}/>

                </form>
            </Container>
        );
    };

    isNumEmpty = (val) => {
        return (val == null || val == "" || isNaN(val));
    }

    sommaPerValori = (attr, val) => {

        let valuesGen = {
            energia: 0,
            carboidrati: 0,
            zuccheri: 0
        }

        let vals2Conv = {
            zuccRes: !this.isNumEmpty(this.state.etichetta.zuccRes) ? parseFloat(this.state.etichetta.zuccRes ) : 0.00,
            alcPres: !this.isNumEmpty(this.state.etichetta.alcPres) ? parseFloat(this.state.etichetta.alcPres): 0.00,
            glicerinaPres:  !this.isNumEmpty(this.state.etichetta.glicerinaPres) ? parseFloat(this.state.etichetta.glicerinaPres): 0.00,
            acPres:  !this.isNumEmpty(this.state.etichetta.acPres) ? parseFloat(this.state.etichetta.acPres): 0.00
        }

        console.log(vals2Conv)
        let isValEmpty = false;
        if (this.isNumEmpty(val)) {
            val = 0.00
            isValEmpty = true;
        }

        vals2Conv[attr] = parseFloat(val)
        vals2Conv.alcPres = parseFloat(parseFloat(vals2Conv.alcPres / 7.894).toFixed(1))

        if(attr == "alcPres"){
            vals2Conv.glicerinaPres = parseFloat(parseFloat(vals2Conv.alcPres *0.7894).toFixed(1))
        }else  if(attr == "glicerinaPres"){
            vals2Conv.alcPres =  parseFloat(parseFloat(vals2Conv.glicerinaPres /0.7894).toFixed(1))
        }
        console.log(vals2Conv, parseFloat(vals2Conv.alcPres))

        //zuccRes
        valuesGen.energia = vals2Conv.zuccRes/10*this.state.converter.generaValoriNutrizionali.carboidrati.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.alcPres*0.7894*this.state.converter.generaValoriNutrizionali.alcool.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.glicerinaPres/10*this.state.converter.generaValoriNutrizionali.glicerina.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.acPres/10*this.state.converter.generaValoriNutrizionali.acidiOrganici.kcalSuG

        let kj = valuesGen.energia * this.state.converter.conversionEnergy.oneKcal2KJ


        valuesGen.energia = Math.round(valuesGen.energia) + "  kcal / " + Math.round(kj) + " kJ"

        valuesGen.carboidrati = new String(parseFloat((vals2Conv.zuccRes + vals2Conv.glicerinaPres) / 10).toFixed(1)) + " g"
        valuesGen.zuccheri = new String((parseFloat(vals2Conv.zuccRes ) / 10).toFixed(1))  + " g"


        if(attr=="alcPres"){
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]: (isValEmpty ? "" : parseFloat(val)),
                    glicerinaPres  :   (isValEmpty ? "" : vals2Conv.glicerinaPres),
                    alcoholPercentage: (isValEmpty ? "" : vals2Conv.alcPres),
                    tabellaValNutrizionali: valuesGen
                }
            }));
        }else  if(attr=="glicerinaPres"){
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]:parseFloat(val),
                    alcPres  :   vals2Conv.alcPres  ,
                    alcoholPercentage: vals2Conv.alcPres,
                    tabellaValNutrizionali: valuesGen
                }
            }));
        }else{
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]:parseFloat(val),
                    //alcoholPercentage: vals2Conv.alcPres,
                    tabellaValNutrizionali: valuesGen
                }
            }));
        }
        console.log(parseFloat(vals2Conv.alcPres).toFixed(1))

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidUpdate() {


    }
    componentDidMount() {

        openModal()
    }
    handleChangeText = (name, val) => {

        if(val == ""){
            this.addRecicleSingleClick("","",name,name);

        }

        this.closeAllList();

        let obj2Save = {...this.state.service[name]};
        obj2Save.text = val;
        obj2Save.hideIngrList = false;
        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [name]: obj2Save
            }
        }));

        if(name=='luogo'){
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    productionPlace: val
                }
            }));
        }




    };
    handleClickText2 = (name, val) => {

        let obj2Save = {...this.state.service[name]};
        obj2Save.hideIngrList = val;
        obj2Save.hideErr=true;
        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [name]: obj2Save
            }
        }));
    };
    closeAllList = () => {

        this.handleClickText2('ingredienti', true)
        this.handleClickText2('conservanti', true)
        this.handleClickText2('contenuti', true)
        this.handleClickText2('luogo', true)
        this.handleClickText2('descrizione', true)

        this.handleClickText2('bottigliaCode', true)
        this.handleClickText2('tappoCode', true)

        this.handleClickText2('gabbiettaCode', true)
        this.handleClickText2('capsulaCode', true)


    };
    closeOtherList= (name) => {

        this.handleClickText2('ingredienti', true)
        this.handleClickText2('conservanti', true)
        this.handleClickText2('contenuti', true)
        this.handleClickText2('luogo', true)
        this.handleClickText2('descrizione', true)
        this.handleClickText2('bottigliaCode', true)
        this.handleClickText2('tappoCode', true)
        this.handleClickText2('gabbiettaCode', true)
        this.handleClickText2('capsulaCode', true)

        this.handleClickText2(name, false)

    };
    handleClickText = (name, val) => {


        this.closeAllList();

        let obj2Save = {...this.state.service[name]};
        obj2Save.hideIngrList = val;
        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [name]: obj2Save
            }
        }));
    };
    handleKeyPress = (event, name) => {
        if (event.key === 'Enter') {
            //  this.addElementList(name);

        }


    };
    addElementList = (name) => {
        if(!this.state.etichetta[name].list.some(item => item === this.state.etichetta[name].text) && this.state.etichetta[name].text.trim() != ""  ){
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [name]: {
                        ...prevState.etichetta[name],
                        list: [...prevState.etichetta[name].list, prevState.etichetta[name].text]
                    }
                }
            }));

            this.setState(prevState => ({
                service: {
                    ...prevState.service,
                    [name]: {
                        ...prevState.service[name],
                        text: '',
                        hideErr: true
                    }
                }
            }));
        }else{
            this.setState(prevState => ({
                service: {
                    ...prevState.service,
                    [name]: {
                        ...prevState.service[name],
                        text: '',
                        hideErr: false
                    }
                }
            }));
        }
    };
    addElementListClick = (el, code, name) => {

        if(!this.state.etichetta[name].list.some(item => item.uuid.toString() === code.toString()) && el.trim() != ""  ){


            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [name]: {
                        ...prevState.etichetta[name],
                        list: [...prevState.etichetta[name].list, {uuid:code.toString(), bold:0}],

                    }
                }
            }));


            this.setState(prevState => ({
                service: {
                    ...prevState.service,
                    [name]: {
                        ...prevState.service[name],
                        selectedEl: el,
                        selectedCode: code.toString(),
                        text: '',
                        hideErr: true,
                        hideIngrList:true
                    }
                }
            }));


        }else{

            this.setState(prevState => ({
                service: {
                    ...prevState.service,
                    [name]: {
                        ...prevState.service[name],
                        hideErr: false
                    }
                }
            }));
        }
    };
    removeElement = (name,val) => {
        let arrList = [...this.state.etichetta[name].list];
        arrList.splice(val.split('_')[2] , 1);
        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [name]: {
                    ...prevState.etichetta[name],
                    list: arrList
                }
            }
        }));
    };
    removeSingleElement = (name,val) => {



        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [name]: ""
            }
        }));

        let obj2Save = {...this.state.service[val]};
        obj2Save.text = "";
        obj2Save.hideIngrList = false;
        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [val]: obj2Save
            }
        }));
    };
    renderBold = (name,val) => {
        if(document.getElementById(val).style.fontWeight != "bold"){
            document.getElementById(val).style.fontWeight="bold"

            if(name=='ingredienti' || name=='contenuti'){

                let codEt = val.split("_")[1];

                let list = this.state.etichetta[name].list.map(item => {
                    if (item.uuid == codEt) {
                        return {uuid:item.uuid, bold: 1};
                    } else {
                        return item;
                    }
                });
                console.log(list)

                this.setState(prevState => ({
                    etichetta: {
                        ...prevState.etichetta,
                        [name]: {
                            ...prevState.etichetta[name],
                            list: list,

                        }
                    }
                }));
            }

        }else{
            document.getElementById(val).style.fontWeight="normal"

            if(name=='ingredienti' || name=='contenuti'){

                let codEt = val.split("_")[1];

                let list = this.state.etichetta[name].list.map(item => {
                    if (item.uuid == codEt) {
                        return {uuid:item.uuid, bold: 0};
                    } else {
                        return item;
                    }
                });
                console.log(list)

                this.setState(prevState => ({
                    etichetta: {
                        ...prevState.etichetta,
                        [name]: {
                            ...prevState.etichetta[name],
                            list: list,

                        }
                    }
                }));


            }

        }


    };
    updateLabel = (name, val) => {

        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [name]: val
            }
        }));

        if(name=='productName2'){
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    productName: val
                }
            }));
        }



    }
    updateLabelAlcool = (name,name2, val) => {

        /*
        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [name]: parseFloat(val),
                [name2]: parseFloat(val)
            }
        }));

         */
        this.sommaPerValori('alcPres', name == "alcoholPercentage" ? parseFloat(parseFloat(val * 7.894).toFixed(1)) : parseFloat(parseFloat(val).toFixed(1) )) ;

    }
    translateElements = (array2Check, element) => {

        let esito = this.state.data[array2Check]
            .filter(item2 => item2.el.toString() === element.toString())
            .map(item2 => item2.el)

        console.log(esito)
        return esito
    }
    checkField = (event, field, id)  => {

        let checkElement = this.translateElements(field, event.target.value)
        if(event.target.value!= "" && checkElement.length == 0){

            this.props.hideElementGlobal(id + 'Span',true)
            document.getElementById(id + 'Span').style.color ="red"

            this.handleTextClick("", "", field, id)
        }else if(this.state.etichetta.productionPlace!=""){
            document.getElementById(id + 'Span').style.color ="green"

        }

    }
    saveEt2Send = (event, etichetta)  => {
        document.getElementById("stateObj").value = JSON.stringify(etichetta)
        document.getElementById("etReq2s").value = document.getElementById('divEditor').outerHTML

        return true;
    }

    warningSuccess = (e,val)  => {
        console.log(e)
        this.closeAllList()

        var inputs = document.getElementsByTagName("input");

        if(document.getElementById("warningInfobox").classList.contains('alert-warning')){
            /*for(var i = 0; i < inputs.length; i++) {
                if(inputs[i].id != 'stateObj' && inputs[i].id !='etReq2s'&& inputs[i].id !='glicerinaPres'){
                    inputs[i].disabled = true;
                }

            }*/
            document.getElementById("warningInfobox").classList.remove('alert-warning');
            document.getElementById("warningInfobox").classList.add('alert-success');
            //document.getElementById("warningInfobox").innerHTML=" <input  type='checkbox' checked> <strong>"+properties.labels.creaEtichetta.warnMessageSuccess+"</strong>"

            //document.getElementById("warningCheckbox").setAttribute("disabled","disabled");
            document.getElementById("submitButton").removeAttribute("disabled");
        }else{
            /*for(var i = 0; i < inputs.length; i++) {
                if(inputs[i].id != 'stateObj' && inputs[i].id !='etReq2s'&& inputs[i].id !='glicerinaPres'){
                    inputs[i].disabled = false;
                }

            }*/
            document.getElementById("warningInfobox").classList.remove('alert-success');
            document.getElementById("warningInfobox").classList.add('alert-warning');
            //document.getElementById("warningInfobox").innerHTML=
            //    "                            <strong>"+  properties.labels.creaEtichetta.warnMessageConfBold+"</strong><br/>"+
            //    "                            <span>"+
            //    "                               <input  type='checkbox' >"
            //    +properties.labels.creaEtichetta.warnMessageConf+
            //    "                        </span>"

            //document.getElementById("warningCheckbox").setAttribute("disabled","disabled");
            document.getElementById("submitButton").setAttribute("disabled","disabled");
        }






    }
    handleClick = (el, code, name) => {


        this.addElementListClick(el,code,name);
    }
    handleTextClick = (el, code, name, stateName) => {

        let checkElement = this.translateElements(name, el)
        this.props.hideElementGlobal(stateName + 'Span',true)

        if(checkElement.length == 0){

            document.getElementById(stateName + 'Span').style.color ="red"

        }else {
            document.getElementById(stateName + 'Span').style.color ="green"

        }
        this.addElementSingleClick(el,code,name,stateName);

    }
    handleRecycleTextClick = (el, code, name, stateName) => {

        let checkElement = this.translateElements(name, el)
        this.props.hideElementGlobal(stateName + 'Span',true)

        if(checkElement.length == 0){

            document.getElementById(stateName + 'Span').style.color ="red"

        }else {
            document.getElementById(stateName + 'Span').style.color ="green"

        }
        this.addRecicleSingleClick(el,code,name,stateName);

    }
    showQrOrTable = (val) => {

        this.setState(prevState => ({
            service: {
                ...prevState.service,
                showQr: val
            }
        }));

    }
    addElementSingleClick = (el, code, name,stateName) => {

        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [stateName]: code
            }
        }));

        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [name]: {
                    ...prevState.service[name],
                    selectedEl: el,
                    selectedCode: code,
                    text: el,
                    hideErr: true,
                    hideIngrList:true
                }
            }
        }));







    };
    addRecicleSingleClick = (el, code, name,stateName) => {

        this.setState(prevState => ({
            riciclo: {
                ...prevState.riciclo,
                [stateName]: code
            }
        }));

        this.setState(prevState => ({
            service: {
                ...prevState.service,
                [stateName]: {
                    ...prevState.service[stateName],
                    selectedEl: el,
                    selectedCode: code,
                    text: el,
                    hideErr: true,
                    hideIngrList:true
                }
            }
        }));

        this.setState(prevState => ({
            etichetta: {
                ...prevState.etichetta,
                [stateName]: code
            }
        }));






    };



    renderIngrCont = (label) => {
        const idxOpen = label?.indexOf("<strong>");
        const idxClose = label?.indexOf("</strong>");
        return (
            <>
                {
                    idxOpen >= 0
                    ? <>{label.substring(0, idxOpen)}<strong>{label.substring(idxOpen + 8, idxClose)}</strong>{label.substring(idxClose + 9)}</>
                    : label
                }
            </>
        );
    };

    renderAllergeniMultilang = (ingredienti, additivi, allergeni, traduzioni, lingueCols) => {
        let allergeniString = "";
        let allergeniLabels = [];
        let array = ingredienti.concat(additivi);

        for (let i = 0; i < array.length; i++) {
            let allergene = allergeni.filter(item => item.code === array[i].uuid);
            if (allergene != null && allergene.length > 0) {
                let labelAllergene = allergene[0].labelAllergene;
                if (labelAllergene != null && !allergeniLabels.includes(labelAllergene)) {
                    allergeniLabels.push(labelAllergene);
                }
            }
        }

        if (allergeniLabels.length > 0) {
            let intestazioni = traduzioni.filter(item => item.label === "allergeni.intestazione");
            for (let i = 0; i < lingueCols.length; i++) {
                if (i > 0) {
                    allergeniString += " - ";
                }
                allergeniString += intestazioni[0][lingueCols[i]] + " ";
                for (let j = 0; j < allergeniLabels.length; j++) {
                    if (j > 0) {
                        allergeniString += ", ";
                    }
                    allergeniString += traduzioni.filter(item => item.label === allergeniLabels[j])[0][lingueCols[i]];
                }
            }
        }

        return (
            <>
                {allergeniString}
            </>
        );
    }

}



function makeHttpCall(urlService){
    let response = {};
    const request = new XMLHttpRequest();
    request.open('GET',properties.fullBaseUrl+properties.baseAppUrl+urlService  , false);  // `false` makes the request synchronous
    request.send(null);
    response = request.response
    try{
        response= JSON.parse(response)
    }catch{
        response= {}
    }
    return response


}

function openModal() {
    document.getElementById("myModal2").style.display = "block";
}

function closeModal() {
    document.getElementById("myModal2").style.display = "none";
}