//import React  , {useState} from 'react';
import React, {Component}  from 'react';
import '../../style/css/App.css';
import {properties} from "../../config/properties";



import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';


export default class WelcomePage extends Component {
    constructor(props) {
        super(props);
    };


    render() {
        var url = new URL( window.location.href);
        console.log(url)
        let langSel = this.props.conf.lang != "" ? "?lang=" + this.props.conf.lang : "";
        //let langUrl =properties.fullBaseUrl +properties.baseAppUrl +"/std/service/loginUser?redirectUrl="  + window.location.origin + properties.baseAppUrl ;

        let langUrl =properties.fullBaseUrl +properties.baseAppUrl +"/std/service/loginUser?redirectUrl="  + window.location.origin + properties.baseAppUrl ;



        return (

            <Container className={"mainContainerView"} style={{paddingLeft:"11%"}}>

                <h3 ><b>{properties.labels.welcomePage.titoloHome}</b></h3>

                <h4 >{properties.labels.welcomePage.descrizioneHome} </h4>

                <br/>
                { this.props.camera.attivo ?
                            <div style={{display: 'flex'}}>

                                <div align={'left'} style={{flex: 1}}>
                                    <label>{this.props.camera.nome}</label>
                                    <br/>
                                    <p>
                                        <h5><b><a className="btn btn-success blue"
                                                  href={langUrl + "/cciaa/" + this.props.camera.sigla.toLowerCase() + "/dashboard"+langSel}
                                                  style={{textDecoration: 'none'}}>{properties.labels.welcomePage.creaEtHome} </a></b>
                                        </h5>
                                    </p>
                                    <br/>
                                </div>

                            </div>:
                            <div style={{display: 'flex'}}>

                                <div align={'left'} style={{flex: 1}}>
                                    <label>{this.props.camera.nome}</label>
                                    <br/>
                                    <p>
                                        <h5><b><a className="btn warning "
                                                  href={properties.baseAppUrl + "/cciaa/" + this.props.camera.sigla.toLowerCase() + "/dashboard"+langSel}
                                                  style={{textDecoration: 'none'}}>{properties.labels.welcomePage.creaEtHome} </a></b>
                                        </h5>
                                    </p>
                                    <br/>
                                </div>

                            </div>
                }

            </Container>
        );
    };

}

