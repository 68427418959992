import React, {Component}  from 'react';
import '../../style/css/App.css';


import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import {properties} from "../../config/properties";

export default class Help extends Component {
    constructor(props) {
        super(props);

    };

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
    }


    componentDidUpdate() {

    }


    render() {

        return (

        <Container style={{ height: '650px', padding:'3.14%',  textAlign:'right' }}>
            <span align={"right"} style={{cursor:"pointer", paddingRight:"11%"}} onClick={() => window.history.back()}><b>❮ {properties.labels.error.indietro}</b></span>

            <Container style={{ width:'93%',  padding: 20, textAlign:'left'}} >

                <div dangerouslySetInnerHTML={{ __html: properties.labels.help.messaggio }}/>

            </Container>

        </Container>
    );
    };

}

