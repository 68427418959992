import React, {useState} from 'react';
import '../../style/css/App.css';

import {
    CardImg,
    Collapse,
    Icon,
    LinkList, LinkListItem,
    MegamenuItem,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler, NavItem,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink


} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import {properties} from "../../config/properties";

const NavbarIC = ({conf,user}) => {


    const [openNav, setOpenNav] = useState(false);
    const toggle = () => {
        setOpenNav(!openNav);
    };


    const languages = [
        { code: 'it', image: '/it-flag.png' },
        { code: 'de', image: '/de-flag.png' },
        { code: 'en', image: '/en-flag.png' }
    ];
    const [dropdownOpen, setDropdownOpen] = useState(false);

    var url = new URL( window.location.href);
    console.log(url)
    let lang2 = url.searchParams.get("lang")!=null ? url.searchParams.get("lang") : "it";
    const [selectedLanguage, setSelectedLanguage] = useState(languages[languages.findIndex(lang => lang.code === lang2)]);



    const toggle2 = () => setDropdownOpen(prevState => !prevState);

    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const toggle3 = () => setDropdownOpen2(prevState => !prevState);



    let langSel = lang2
    let langUrl = langSel == 'it' ? "/" : "/?lang=" + langSel;
    let langUrlHelp = langSel == 'it' ? "/help" : "/help?lang=" + langSel;


    const changeLang = (newLang) => {
        url.searchParams.set('lang', newLang)
        window.location.href = url.href;
    };
    return (
        <Navbar expand='lg' className='has-megamenu' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Nav className='mt-0 navbar'>
                <NavItem style={{ margin: "10px" }}>
                    <a className='linkNav' style={{ textDecoration: "none" }} href={properties.baseAppUrl + langUrl}>
                        <CardImg src={properties.baseAppUrl + properties.baseImages + '/ic-logo.svg'} height={'50'} />
                    </a>
                </NavItem>
            </Nav>

            <Nav>
                {/* Other NavItems */}

                <NavItem style={{margin:"10px"}} className={"userNavMobile"}>
                    <Dropdown  isOpen={dropdownOpen2} toggle={toggle3} className="dropdown-center">
                        <DropdownToggle className={"navSelLang"} style={{  backgroundColor:"#06c"}} caret>
                            <img style={{ borderRadius: "50%", backgroundColor:'white'  }} src={properties.baseAppUrl + properties.baseImages + "/userLogin.png"} height={'19'} width={'19'} />

                        </DropdownToggle>
                        {user!= "" ?
                            <DropdownMenu className="userNav">
                                <div style={{ backgroundColor: 'white' }}>
                                    <DropdownItem key={0} style={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        <label>{user}</label>
                                    </DropdownItem>

                                    <DropdownItem key={1}>
                                        <a
                                            style={{ textDecoration: "none" }}
                                            href={properties.fullBaseUrl + properties.baseAppUrl + "/std/service/logoutUser" + (langSel == 'it' ? "?langSel=" : "?langSel=" + langSel)}
                                        >
                                            LOG OUT
                                        </a>
                                    </DropdownItem>
                                </div>
                            </DropdownMenu>
                            :
                            <DropdownMenu className={"userNav"}>
                                <div style={{backgroundColor: 'white'}}>
                                    <DropdownItem key={0}>

                                        <a style={{textDecoration: "none"}}
                                           href={properties.fullBaseUrl + properties.baseAppUrl + "/std/service/loginUser?redirectUrl=" + window.location.href}>LOG
                                            IN</a>


                                    </DropdownItem>
                                </div>
                            </DropdownMenu>

                        }

                    </Dropdown>

                </NavItem>
                <NavItem style={{margin:"10px"}} >
                    <Dropdown  isOpen={dropdownOpen} toggle={toggle2} >
                        <DropdownToggle className={"navSelLang"} style={{  backgroundColor:"#06c"}} >
                            <img style={{borderRadius: "50%"}}
                                 src={properties.baseAppUrl + properties.baseImages + selectedLanguage.image}
                                 height={'12'} width={'12'}/>

                        </DropdownToggle>
                        <DropdownMenu className={"userNav"}>
                            <div style={{backgroundColor: 'white'}}>
                                {languages.map((lang, index) => (
                                    <a onClick={() => changeLang(lang.code)} style={{color: "white"}}>
                                        <DropdownItem key={index} onClick={() => setSelectedLanguage(lang)}>

                                            <img style={{borderRadius: "50%"}}
                                                 src={properties.baseAppUrl + properties.baseImages + lang.image}
                                                 height={'25'} width={'25'}/>

                                        </DropdownItem>
                                    </a>
                                ))}
                            </div>
                        </DropdownMenu>

                    </Dropdown>

                </NavItem>


                <NavItem style={{margin:"10px"}} >
                    <div style={{justifyContent:"center",margin:"10px"}}>
                    <a href={properties.baseAppUrl + langUrlHelp}  style={{  backgroundColor:"#06c", textDecoration:'none'}}>
                        <img style={{ borderRadius: "50%", backgroundColor:'white'  }} src={properties.baseAppUrl + properties.baseImages + "/userHelp.png"} height={'19'} width={'19'} />
                    </a>
                    </div>

                </NavItem>
            </Nav>
        </Navbar>
    );
};
function getUserJS(){
    let response = {};
    const request = new XMLHttpRequest();
    request.open('GET', properties.baseAppUrl + properties.loggedUserUrl, false);  // `false` makes the request synchronous
    request.send(null);
    response = request.response

    try{
        response= JSON.parse(response)

    }catch{
        response= {userId : "Non autenticato"}

    }

    return response


}
export default NavbarIC;