import React from 'react';
import '../../style/css/App.css';

import {
    Col,
    Container,
    Icon,
    Row
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import {properties} from "../../config/properties";

const Footer = () => {

    var url = new URL( window.location.href);
    let lang2 = url.searchParams.get("lang")!=null ? url.searchParams.get("lang") : "it";

    return (
        <footer className="it-footer">
            <div className="it-footer-main">

                <Container>
                    <section>
                        <Row className="clearfix">

                            <Col sm={5}>
                                <div className="it-brand-text" align={"left"}>
                                    <br/>
                                    <h6>{properties.labels.footer.gestore}</h6>

                                    {properties.labels.footer.nomeGestore}
                                    <br/>
                                    {properties.labels.footer.indirizzoGestore}
                                    <br/>
                                    {properties.labels.footer.pivaCFgestore}
                                    <br/>
                                    {properties.labels.footer.reaGestore}
                                    <br/>
                                    {properties.labels.footer.telGestore}
                                    <br/>
                                    <a href={"mailto:" + properties.labels.footer.emailGestore_1}> {properties.labels.footer.emailGestore_1} </a>
                                    <br/>
                                    <a href={"mailto:" + properties.labels.footer.emailGestore_2}> {properties.labels.footer.emailGestore_2} </a>
                                    <br/>
                                    <a target={"_blank"} href={"https://" + properties.labels.footer.sitoGestore}> {properties.labels.footer.sitoGestore} </a>
                                    <br/>
                                </div>
                            </Col>

                            <Col sm={2}>
                            </Col>

                            <Col sm={5}>
                                <div className="it-brand-text" align={"right"}>
                                    <br/>
                                    <h6>{properties.labels.footer.servizioRealizzato}  &nbsp;
                                        <a target={"_blank"} href={"https://" + properties.labels.footer.sitoIC}>
                                            <Icon src={properties.baseAppUrl+properties.baseImages + '/ic-logo.svg'} height={'50'} />
                                        </a>
                                    </h6>

                                    {properties.labels.footer.nomeIC}
                                    <br/>
                                    {properties.labels.footer.descizioneIC}
                                    <br/>
                                    {properties.labels.footer.indirizzoIC}
                                    <br/>
                                    {properties.labels.footer.pivaCFIC}
                                    <br/>
                                    {properties.labels.footer.reaIC}
                                    <br/>
                                    {properties.labels.footer.codLeiIC}
                                    <br/>
                                    <a target={"_blank"} href={"https://" + properties.labels.footer.sitoIC}> {properties.labels.footer.sitoIC} </a>
                                    <br/>
                                    <br/>
                                    <div className="it-brand-text" align={"right"}>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.instagram.com/infocamere/">
                                            <img
                                                src={"https://www.infocamere.it/.resources/InfoCamere-Module/webresources/img/icn/icn_instagram.svg"}/>
                                        </a></span>

                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<a
                                            href="https://www.facebook.com/InfoCamere">
                                         <img
                                             src={"https://www.infocamere.it/.resources/InfoCamere-Module/webresources/img/icn/icn_facebook.svg"}/>
                                        </a></span>

                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://x.com/infocamere">

                                        <img
                                            src={"https://www.infocamere.it/.resources/InfoCamere-Module/webresources/img/icn/icn_twitter.svg"}/>
                                    </a></span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.linkedin.com/company/infocamere">
                                            <img
                                                src={"https://www.infocamere.it/.resources/InfoCamere-Module/webresources/img/icn/icn_linkedin.svg"}/>
                                        </a></span>

                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.youtube.com/user/ICRoma">
                                            <img
                                                src={"https://www.infocamere.it/.resources/InfoCamere-Module/webresources/img/icn/icn_youtube.svg"}/>
                                        </a></span>


                                    </div>


                                </div>

                            </Col>
                        </Row>
                    </section>

                </Container>
                <br/>
            </div>

            <div className="it-footer-small-prints clearfix">
                <Container>

                  <br/>

                    <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row"
                        style={{color: "white"}}>

                        <li className="list-inline-item">

                            <a href={properties.baseAppUrl + "/static/files" + '/2024%2007%2012%20EVIN%20Condizioni%20generali%20d%27uso.pdf'}
                               target={"_blank"}>{properties.labels.footer.condizioniUso}</a>
                        </li>
                        <li className="list-inline-item">

                            <a href={properties.baseAppUrl + "/static/files" + '/2024%2007%2011%20EVIN%20Privacy.pdf'}
                               target={"_blank"}>{properties.labels.footer.privacy}</a>
                        </li>

                    </ul>


                </Container>


            </div>

        </footer>
    );
};

export default Footer;