import localProp from './frnt-properties.json'
function getProp(){

    let getProps = {}
    try{
        let envProp = {};
        const request = new XMLHttpRequest();
        request.open('GET', localProp.baseAppUrl + localProp.properties, false);  // `false` makes the request synchronous
        request.send(null);
        envProp = request.response
        console.log("env",envProp)
        envProp = JSON.parse(envProp)
        envProp.fullBaseUrl = ""
        console.log("env-modified",envProp)
        getProps =  envProp
    }catch (e){
        localProp.fullBaseUrl = ("localhost:3000" === window.location.host) ? process.env["REACT_APP_LOCAL_FRONTEND_URL"] : "";
        console.log("local",localProp)
        getProps =  localProp
    }

    var url = new URL( window.location.href);
    let lang = url.searchParams.get("lang")!=null ? url.searchParams.get("lang") : "it";

    let propertiesLang = getProps
    propertiesLang.labels = getProps.labels[lang]
    propertiesLang.data.ingredienti = getProps.data.ingredienti[lang]
    propertiesLang.data.conservanti = getProps.data.conservanti[lang]
    propertiesLang.data.contenuti = getProps.data.contenuti[lang]
    propertiesLang.data.prodotti = getProps.data.prodotti[lang]
    propertiesLang.data.luogo = getProps.data.luogo[lang]
    propertiesLang.data.descrizione = getProps.data.descrizione[lang]
    propertiesLang.data.bottigliaCode = getProps.data.bottigliaCode[lang]
    propertiesLang.data.tappoCode = getProps.data.tappoCode[lang]
    propertiesLang.data.gabbiettaCode = getProps.data.gabbiettaCode[lang]
    propertiesLang.data.capsulaCode = getProps.data.capsulaCode[lang]


    console.log(getProps)
   return propertiesLang

}


export const properties = getProp();


