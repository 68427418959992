import React, {Component}  from 'react';
import '../../style/css/App.css';


import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import {properties} from "../../config/properties";

export default class AlertMessage extends Component {
    constructor(props) {
        super(props);

    };

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
    }


    componentDidUpdate() {

    }


    render() {

        return (

        <Container style={{ minHeight: '350px', margin: 20, padding: 20, textAlign:'right' }}>
            <span align={"right"} style={{cursor:"pointer", width:"11%"}} onClick={() => window.history.back()}><b>❮ {properties.labels.error.indietro}</b></span>

            <Container style={{ width:'66%',  marginLeft: '23%', padding: 20, textAlign:'left'}} >

                <p dangerouslySetInnerHTML={{ __html: this.props.cciaa.item.emergencyMessagePopup }}/>


            </Container>

        </Container>
    );
    };

}

