


//import React  , {useState} from 'react';
import React, {Component}  from 'react';
import '../../style/css/App.css';
import {properties} from "../../config/properties";
import styles from '../../style/css/styles.json'



import {
    Container
} from "design-react-kit";

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';


export default class AnteprimaEtichetta extends Component {
    constructor(props) {
        super(props);

        let url = new URL( window.location.href);
        let idEt = url.searchParams.get("id")!=null ? url.searchParams.get("id") : "";

        //scarico etichetta da id
        let getEtichetta = makeHttpCall(properties.getEtichetta+"?id="+ idEt)
        //scarico liste ingredienti, contenuti e traduzioni
        let getListaIngredienti = makeHttpCall(properties.getIngredienti)
        let getListaContenuti = makeHttpCall(properties.getContenuti)
        let getListaTraduzioni = makeHttpCall(properties.getTraduzioni)

        //inizializzo lo state
        this.state = {
            service:{
                showQr:false,
                showTable:false,
                prodotti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                luogo:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                descrizione:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                ingredienti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                conservanti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                contenuti:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                bottigliaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                tappoCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                gabbiettaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                },
                capsulaCode:{
                    hideErr:true,
                    showMenu:true,
                    text:"",
                    hideIngrList:true,
                    selectedEl: '',
                    selectedCode: '',
                }



            },
            data:properties.data,
            converter:properties.converter,

        };
        this.state.riciclo= {
            "bottigliaCode": getEtichetta.bottigliaCode ,
            "tappoCode": getEtichetta.tappoCode ,
            "gabbiettaCode": getEtichetta.gabbiettaCode ,
            "capsulaCode": getEtichetta.capsulaCode

        }
        //valorizzo etichetta
        this.state.etichetta = getEtichetta
        this.state.etichetta.productYear = new Date().getFullYear()


        //valorizzo liste da tabelle
        this.state.data.ingredienti = getListaIngredienti.map(item2 => ({"el": item2['nome' + this.props.conf.lang.charAt(0).toUpperCase() + this.props.conf.lang.slice(1)],
            "code":item2.codiceIngrediente  }  ))
        this.state.data.contenuti = getListaContenuti.map(item2 => ({"el": item2['nome' + this.props.conf.lang.charAt(0).toUpperCase() + this.props.conf.lang.slice(1)],
            "code":item2.codiceIngrediente  }  ))
        this.state.data.traduzioni = getListaTraduzioni

        //creo una lista contenente tutti gli ingredienti/contenuti allergeni, con codice elemento e label allergene
        this.state.data.allergeni =
            [ ...(getListaIngredienti
                .filter(item2 => item2.labelAllergene !== null)
                .map(item2 => ({"code":item2.codiceIngrediente, "labelAllergene": item2.labelAllergene}))),
                ...(getListaContenuti
                    .filter(item2 => item2.labelAllergene !== null)
                    .map(item2 => ({"code":item2.codiceIngrediente, "labelAllergene": item2.labelAllergene})))]

    }

    render() {


        return (

            <Container style={{minHeight: '480px', padding: "3.14%"}}>

                <div align={"right"}>
                    <span  style={{cursor: "pointer", paddingRight: "11%"}}
                          onClick={() => window.history.back()}><b>❮ {properties.labels.error.indietro}</b></span>
                </div>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-around', width: "100%"}} id={"mainCont"}>
                        <div id="divEditor">

                            <div style={styles.divEtichetta} id="divEtichetta">

                                { /*
                                <div hidden={true} style={{textAlign: 'center'}}>


                                    <span
                                        hidden={this.state.etichetta.productName2 == true ? true : false}>{properties.labels.creaEtichetta.etichetta.productNameLabel}
                                        <label
                                            style={styles.itemEtList}
                                            className={"itemEtList"}
                                            onClick={() => this.renderBold("prodotti", "productNameLabel")}
                                            id="productNameLabel">{this.state.etichetta.productName}</label></span>

                                </div>
                                <div hidden={true} style={{textAlign: 'center'}}>



                                   <span>
                                       <label style={styles.itemEtList}
                                              className={"itemEtList"}
                                              onClick={() => this.renderBold("prodotti", "productYearLabel")}
                                              id="productYearLabel">{this.state.etichetta.productYear}</label>
                                   </span>


                                </div>
                                <br/>
                                <div
                                    hidden={true}>
                                        <span> <label
                                            id="bottleSizeLabel">{this.state.etichetta.bottleSize}</label> <span
                                            hidden={this.state.etichetta.bottleSize == "" || this.state.etichetta.bottleSize == null && true}>L - </span> <span> <label
                                            id="alcoholPercentageLabel">{(this.state.etichetta.alcoholPercentage != null && this.state.etichetta.alcoholPercentage != 'NaN') ? this.state.etichetta.alcoholPercentage : "0"}</label> <span
                                            hidden={this.state.etichetta.alcoholPercentage == "" || this.state.etichetta.alcoholPercentage == null && true}>%</span></span></span>
                                </div>
                                */ }

                                {this.state.service.showQr ?
                                    <p>



                                            <span id="contieneList"
                                                  style={styles.contieneList}>{properties.labels.creaEtichetta.etichetta.contenuti}:&nbsp;

                                                {this.state.etichetta.contenuti.list.map((item, index) => (
                                                    <span
                                                        id={"contenuti_" + item.uuid.toString() + "_" + index}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'
                                                        }}
                                                        className={"itemEtList"}
                                                        key={index}
                                                        //onDoubleClick={() => this.removeElement("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}
                                                        //onClick={() => this.renderBold("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}

                                                        title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                                    >
                                                        {
                                                            this.state.data.contenuti
                                                                .filter(item2 => item2.code.toString() === item.uuid.toString())
                                                                .map(item2 => this.renderIngrCont(item2.el))
                                                        }
                                                        {
                                                            (index < this.state.etichetta.contenuti.list.length - 1 ? ", " : " ")
                                                        }
                                                    </span>
                                                ))

                                                }
                                            </span>


                                    </p>
                                    :
                                    <p>
                        <span id="ingredientsList"

                              style={styles.ingredientsList}>
                            <label>{properties.labels.creaEtichetta.etichetta.ingredienti}:&nbsp;</label>
                            {this.state.etichetta.ingredienti.list.map((item, index) => (
                                <span
                                    id={"ingredienti_" + item.uuid.toString() + "_" + index}
                                    style={{cursor: "pointer", fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'}}
                                    className={"itemEtList"}
                                    key={index}
                                    //onDoubleClick={() => this.removeElement("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}
                                    //onClick={() => this.renderBold("ingredienti", "ingredienti_" + item.uuid.toString() + "_" + index)}

                                    title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                >

                                    {
                                        this.state.data.ingredienti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.ingredienti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                            ))

                            }
                                </span>
                                        <br/>
                                        <span id="contieneList"


                                              style={styles.contieneList}>

                                            {this.state.etichetta.contenuti.list.map((item, index) => (
                                                <span
                                                    id={"contenuti_" + item.uuid.toString() + "_" + index}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: /*item.bold == 1 ? 'bold' :*/ 'normal'
                                                    }}
                                                    className={"itemEtList"}
                                                    key={index}
                                                    //onDoubleClick={() => this.removeElement("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}
                                                    //onClick={() => this.renderBold("contenuti", "contenuti_" + item.uuid.toString() + "_" + index)}

                                                    title={properties.labels.creaEtichetta.etichetta.rimuoviElemento}
                                                >
                                    {
                                        this.state.data.contenuti
                                            .filter(item2 => item2.code.toString() === item.uuid.toString())
                                            .map(item2 => this.renderIngrCont(item2.el))
                                    }
                                    {
                                        (index < this.state.etichetta.contenuti.list.length - 1 ? ", " : " ")
                                    }
                                </span>
                                            ))

                                            }
                        </span>

                                    </p>


                                }


                                {this.state.service.showQr ?
                                    <div style={styles.qrPar} onClick={(e) => {
                                        this.showQrOrTable(!this.state.service.showQr)
                                    }}>
                                        <p>
                                            {properties.labels.creaEtichetta.etichetta.qrCode}

                                        </p>
                                        <div align={"right"}>

                                            <img style={{width: "30%", padding: "5px"}}
                                                 src={properties.fullBaseUrl + properties.baseAppUrl + "/std/service/getQRCode?id=" + this.state.etichetta.codiceEtichetta}/>

                                        </div>
                                    </div>
                                    :
                                    <table id="tblNutrition" style={styles.tblNutrition} onClick={(e) => {
                                        //   this.showQrOrTable(!this.state.service.showQr)
                                    }}>
                                        <tr>
                                            <th style={styles.th}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.intestazione1}</th>
                                            <th style={styles.th}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.intestazione2}</th>
                                        </tr>
                                        <tr>
                                            <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.energia}</td>
                                            <td style={styles.td}>
                                                <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.energia.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.energia
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.carboidrati}</td>
                                            <td style={styles.td}>
                                                <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.carboidrati.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.carboidrati
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.zuccheri}</td>
                                            <td style={styles.td}>
                                                <span>
                                                    { (this.props.conf.lang === "it" || this.props.conf.lang === "de")
                                                        ? this.state.etichetta.tabellaValNutrizionali.zuccheri.replaceAll(".", ",")
                                                        : this.state.etichetta.tabellaValNutrizionali.zuccheri
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td}>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.bottom1}<br/>{properties.labels.creaEtichetta.etichetta.tabellaNutrizionale.bottom2}
                                            </td>
                                            <td style={styles.td}></td>
                                        </tr>

                                    </table>

                                }

                                    <div style={styles.labelSection} >
                                        <br/>
                                        <br/>
                                        {
                                            this.renderAllergeniMultilang(this.state.etichetta.ingredienti.list,
                                                this.state.etichetta.contenuti.list,
                                                this.state.data.allergeni,
                                                this.state.data.traduzioni,
                                                this.state.data.lingueCols)
                                        }
                                        <br/>
                                        <br/>
                                    </div>

                                { /*
                                <div id="labelSection" style={styles.labelSection}>
                                    <br/>
                                    <span
                                        hidden={true}>{properties.labels.creaEtichetta.etichetta.productionPlaceLabel} &nbsp;

                                        <label id="productionPlaceLabel"
                                               onClick={() => this.renderBold("luogo", "productionPlaceLabel")}
                                               onDoubleClick={() => this.removeSingleElement("productionPlace", "luogo")}
                                               style={styles.itemEtList}
                                               className={"itemEtList"}

                                        >
                                        {
                                            this.state.etichetta.productionPlace
                                        }
                                    </label>

                                </span>


                                    <br/>
                                    <span
                                        hidden={true}>{properties.labels.creaEtichetta.etichetta.bottledByLabel} &nbsp;
                                        <label style={styles.itemEtList}
                                               className={"itemEtList"}
                                               onClick={() => this.renderBold("prodotti", "bottledByLabel")}
                                               id="bottledByLabel">{this.state.etichetta.bottledBy}
                                            </label>
                                        </span>
                                    <br/>
                                    <span
                                        hidden={true}>{properties.labels.creaEtichetta.etichetta.lotNumberLabel} &nbsp;
                                        <label style={styles.itemEtList}
                                               onClick={() => this.renderBold("prodotti", "lotNumberLabel")}
                                               className={"itemEtList"}
                                               id="lotNumberLabel">{this.state.etichetta.lotNumber}
                                            </label>
                                        </span>
                                    <br/>
                                    <span hidden={true}>
                                    <label id="descriptionLabel"
                                           onClick={() => this.renderBold("descrizione", "descriptionLabel")}
                                           onDoubleClick={() => this.removeSingleElement("description", "descrizione")}
                                           style={styles.itemEtList}
                                           className={"itemEtList"}

                                    >
                                            {this.state.etichetta.description != null && this.state.etichetta.description != 6 ?
                                                this.state.data.descrizione
                                                    .filter(item2 => item2.code.toString() === this.state.etichetta.description.toString())
                                                    .map(item2 => item2.el) : ""
                                            }
                                        </label>

                                    </span>


                                </div>
                                */ }


                                <br/>
                            </div>
                            <br/>
                            <div id="bottledEditor" /*onClick={(e) => this.closeAllList()}*/>
                                <br/>
                                <br/>
                                <img style={{width: "20%", padding: "5px"}}
                                     src={properties.baseAppUrl + properties.baseImages + '/anonymus_bottle.png'}/>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '75%',
                                    textAlign: 'center'
                                }}>
                                    <table id="bottleTable"
                                           style={{borderCollapse: 'collapse', marginLeft: '20px auto', width: '100%'}}>
                                        <tr>
                                            <th style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#C8F8FC'
                                            }}>{properties.labels.creaEtichetta.riciclo.bottigliaLabel}</th>
                                            <th style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#C8F8FC'
                                            }}>{properties.labels.creaEtichetta.riciclo.tappoLabel}</th>
                                            {this.state.riciclo.gabbiettaCode != null && <th style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#C8F8FC'
                                            }}>{properties.labels.creaEtichetta.riciclo.gabbiettaLabel}</th>}
                                            {this.state.riciclo.capsulaCode != null && <th style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#C8F8FC'
                                            }}>{properties.labels.creaEtichetta.riciclo.capsulaLabel}</th>}

                                        </tr>

                                        <tr>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                backgroundColor: 'lightgrey',
                                                color: "#06c"
                                            }}>
                                                <b> {this.state.riciclo.bottigliaCode != null ?
                                                    this.state.data.bottigliaCode
                                                        .filter(item2 => item2.code.toString() === this.state.riciclo.bottigliaCode.toString())
                                                        .map(item2 => item2.codice) : ""
                                                }</b>

                                            </td>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                backgroundColor: 'lightgrey',
                                                color: "#06c"
                                            }}>
                                                <b>{this.state.riciclo.tappoCode != null ?
                                                    this.state.data.tappoCode
                                                        .filter(item2 => item2.code.toString() === this.state.riciclo.tappoCode.toString())
                                                        .map(item2 => item2.codice) : ""
                                                }</b>
                                            </td>
                                            {this.state.riciclo.gabbiettaCode != null &&
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b>  {this.state.riciclo.gabbiettaCode != null ?
                                                        this.state.data.gabbiettaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.gabbiettaCode.toString())
                                                            .map(item2 => item2.codice) : ""
                                                    }</b>
                                                </td>
                                            }

                                            {this.state.riciclo.capsulaCode != null &&
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b> {this.state.riciclo.capsulaCode != null ?
                                                        this.state.data.capsulaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.capsulaCode.toString())
                                                            .map(item2 => item2.codice) : ""
                                                    }</b>
                                                </td>
                                            }


                                        </tr>
                                        <tr>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                backgroundColor: 'lightgrey',
                                                color: "#06c"
                                            }}>
                                                <b> {this.state.riciclo.bottigliaCode != null ?
                                                    this.state.data.bottigliaCode
                                                        .filter(item2 => item2.code.toString() === this.state.riciclo.bottigliaCode.toString())
                                                        .map(item2 => item2.el) : ""
                                                }</b>
                                            </td>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                backgroundColor: 'lightgrey',
                                                color: "#06c"
                                            }}>
                                                <b>  {this.state.riciclo.tappoCode != null ?
                                                    this.state.data.tappoCode
                                                        .filter(item2 => item2.code.toString() === this.state.riciclo.tappoCode.toString())
                                                        .map(item2 => item2.el) : ""
                                                }
                                                    {this.state.riciclo.tappoEl}</b></td>

                                            {this.state.riciclo.gabbiettaCode != null &&
                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b>  {this.state.riciclo.gabbiettaCode != null ?
                                                        this.state.data.gabbiettaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.gabbiettaCode.toString())
                                                            .map(item2 => item2.el) : ""
                                                    }
                                                        {this.state.riciclo.tappoEl}</b></td>
                                            }

                                            {this.state.riciclo.capsulaCode != null &&

                                                <td style={{
                                                    border: '3px solid white',
                                                    padding: '8px',
                                                    backgroundColor: 'lightgrey',
                                                    color: "#06c"
                                                }}>
                                                    <b>  {this.state.riciclo.capsulaCode != null ?
                                                        this.state.data.capsulaCode
                                                            .filter(item2 => item2.code.toString() === this.state.riciclo.capsulaCode.toString())
                                                            .map(item2 => item2.el) : ""
                                                    }
                                                        {this.state.riciclo.tappoEl}</b></td>
                                            }

                                        </tr>


                                    </table>
                                    <table id="tblBottle2" style={{
                                        borderCollapse: 'collapse',
                                        marginLeft: '20px auto',
                                        width: '100%',
                                        border: '1px solid #ddd'
                                    }}>
                                        <tr>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#58D5DF'
                                            }}><b>{properties.labels.creaEtichetta.riciclo.descrizione2}</b></td>
                                        </tr>

                                        <tr>
                                            <td style={{
                                                border: '3px solid white',
                                                padding: '8px',
                                                color: '#06c',
                                                backgroundColor: '#C8F8FC'
                                            }}><b>{properties.labels.creaEtichetta.riciclo.descrizione3}</b></td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                            <br/>
                        </div>

                    </div>

                </div>


                <input type="text" name={"stateObj"} id={"stateObj"} hidden={true}/>
                <input type="text" name={"etReq2s"} id={"etReq2s"} hidden={true}/>


            </Container>
        );
    };


    showQrOrTable = (val) => {

        this.setState(prevState => ({
            service: {
                ...prevState.service,
                showQr: val
            }
        }));

    }
    sommaPerValori = (attr, val) => {

        let valuesGen = {
            energia: 0,
            carboidrati: 0,
            zuccheri: 0
        }

        let vals2Conv = {
            zuccRes: this.state.etichetta.zuccRes != null ? parseFloat(this.state.etichetta.zuccRes) : 0.00,
            alcPres: this.state.etichetta.alcPres != null ? parseFloat(this.state.etichetta.alcPres) : 0.00,
            glicerinaPres: this.state.etichetta.glicerinaPres != null ? parseFloat(this.state.etichetta.glicerinaPres) : 0.00,
            acPres: this.state.etichetta.acPres != null ? parseFloat(this.state.etichetta.acPres) : 0.00
        }

        console.log(vals2Conv)
        if (val == null || val == NaN || val == "") {
            val = 0.00
        }

        vals2Conv[attr] = parseFloat(val)
        vals2Conv.alcPres = parseFloat(parseFloat(vals2Conv.alcPres / 7.894).toFixed(1))

        if (attr == "alcPres") {
            vals2Conv.glicerinaPres = parseFloat(parseFloat(vals2Conv.alcPres * 0.7894).toFixed(1))
        } else if (attr == "glicerinaPres") {
            vals2Conv.alcPres = parseFloat(parseFloat(vals2Conv.glicerinaPres / 0.7894).toFixed(1))
        }
        console.log(vals2Conv, parseFloat(vals2Conv.alcPres))

        //zuccRes
        valuesGen.energia = vals2Conv.zuccRes / 10 * this.state.converter.generaValoriNutrizionali.carboidrati.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.alcPres * 0.7894 * this.state.converter.generaValoriNutrizionali.alcool.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.glicerinaPres / 10 * this.state.converter.generaValoriNutrizionali.glicerina.kcalSuG
        //alcPres
        valuesGen.energia += vals2Conv.acPres / 10 * this.state.converter.generaValoriNutrizionali.acidiOrganici.kcalSuG

        let kj = valuesGen.energia * this.state.converter.conversionEnergy.oneKcal2KJ


        valuesGen.energia = Math.round(valuesGen.energia) + "  kcal / " + Math.round(kj) + " kJ"

        valuesGen.carboidrati = new String(parseFloat((vals2Conv.zuccRes + vals2Conv.glicerinaPres) / 10).toFixed(1)) + " g"
        valuesGen.zuccheri = new String((parseFloat(vals2Conv.zuccRes) / 10).toFixed(1)) + " g"


        if (attr == "alcPres") {
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]: parseFloat(val),
                    glicerinaPres: vals2Conv.glicerinaPres,
                    alcoholPercentage: vals2Conv.alcPres,
                    tabellaValNutrizionali: valuesGen
                }
            }));
        } else if (attr == "glicerinaPres") {
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]: parseFloat(val),
                    alcPres: vals2Conv.alcPres,
                    alcoholPercentage: vals2Conv.alcPres,
                    tabellaValNutrizionali: valuesGen
                }
            }));
        } else {
            this.setState(prevState => ({
                etichetta: {
                    ...prevState.etichetta,
                    [attr]: parseFloat(val),
                    alcoholPercentage: vals2Conv.alcPres,
                    tabellaValNutrizionali: valuesGen
                }
            }));
        }
        console.log(parseFloat(vals2Conv.alcPres).toFixed(1))

    }


    componentDidMount() {

        this.sommaPerValori('zuccRes', this.state.etichetta.zuccRes)


    }


    renderIngrCont = (label) => {
        const idxOpen = label?.indexOf("<strong>");
        const idxClose = label?.indexOf("</strong>");
        return (
            <>
                {
                    idxOpen >= 0
                        ? <>{label.substring(0, idxOpen)}<strong>{label.substring(idxOpen + 8, idxClose)}</strong>{label.substring(idxClose + 9)}</>
                        : label
                }
            </>
        );
    };


    renderAllergeniMultilang = (ingredienti, additivi, allergeni, traduzioni, lingueCols) => {
        let allergeniString = "";
        let allergeniLabels = [];
        let array = ingredienti.concat(additivi);

        for (let i = 0; i < array.length; i++) {
            let allergene = allergeni.filter(item => item.code === array[i].uuid);
            if (allergene != null && allergene.length > 0) {
                let labelAllergene = allergene[0].labelAllergene;
                if (labelAllergene != null && !allergeniLabels.includes(labelAllergene)) {
                    allergeniLabels.push(labelAllergene);
                }
            }
        }

        if (allergeniLabels.length > 0) {
            let intestazioni = traduzioni.filter(item => item.label === "allergeni.intestazione");
            for (let i = 0; i < lingueCols.length; i++) {
                if (i > 0) {
                    allergeniString += " - ";
                }
                allergeniString += intestazioni[0][lingueCols[i]] + " ";
                for (let j = 0; j < allergeniLabels.length; j++) {
                    if (j > 0) {
                        allergeniString += ", ";
                    }
                    allergeniString += traduzioni.filter(item => item.label === allergeniLabels[j])[0][lingueCols[i]];
                }
            }
        }

        return (
            <>
                {allergeniString}
            </>
        );
    }

}


function makeHttpCall(urlService) {
    let response = {};
    const request = new XMLHttpRequest();
    request.open('GET', properties.fullBaseUrl + properties.baseAppUrl + urlService, false);  // `false` makes the request synchronous
    request.send(null);
    response = request.response
    try {
        response = JSON.parse(response)
    } catch {
        response = {}
    }
    return response


}
